import '../../App.css';
import React, { useContext, useState, useEffect } from "react";
import { ApiContext } from "../../context/ApiContext";
import { Tab, Tabs, Form, Row, Col } from 'react-bootstrap';
import { FaTimes, FaPlus } from 'react-icons/fa';
import { TbTrash } from "react-icons/tb";
import { StyleContext } from '../../context/StyleContext';

function ModalCadastrarSwot({ closeModal }) {
    const { CriarSwot, Swot, setSwot, ForcaSwot, setForcaSwot, FraquezaSwot, setFraquezaSwot, OportunidadeSwot, setOportunidadeSwot, AmeacaSwot, setAmeacaSwot, ListaCapacidadesPorArea, CapacidadesPorArea, Areas, Ciclos, setError } = useContext(ApiContext)
    const { btnVer, btnCriar, btnPesquisar } = useContext(StyleContext)

    const [areaKey, setAreaKey] = useState("")

    const [ForcaKey, setForcaKey] = useState()
    const [ForcaG, setForcaG] = useState(0)
    const [ForcaU, setForcaU] = useState(0)
    const [ForcaT, setForcaT] = useState(0)
    const [ForcaGUT, setForcaGUT] = useState(0)

    const [FraquezaKey, setFraquezaKey] = useState()
    const [FraquezaG, setFraquezaG] = useState(0)
    const [FraquezaU, setFraquezaU] = useState(0)
    const [FraquezaT, setFraquezaT] = useState(0)
    const [FraquezaGUT, setFraquezaGUT] = useState(0)

    const [OportunidadeKey, setOportunidadeKey] = useState()
    const [OportunidadeG, setOportunidadeG] = useState(0)
    const [OportunidadeU, setOportunidadeU] = useState(0)
    const [OportunidadeT, setOportunidadeT] = useState(0)
    const [OportunidadeGUT, setOportunidadeGUT] = useState(0)

    const [AmeacaKey, setAmeacaKey] = useState()
    const [AmeacaG, setAmeacaG] = useState(0)
    const [AmeacaU, setAmeacaU] = useState(0)
    const [AmeacaT, setAmeacaT] = useState(0)
    const [AmeacaGUT, setAmeacaGUT] = useState(0)

    const handleChange = (event) => {
        setSwot({ ...Swot, [event.target.name]: event.target.value })
    }
    const handleChangeArea = (event) => {
        if (ForcaSwot.length > 0 || FraquezaSwot.length > 0 || OportunidadeSwot.length > 0 || AmeacaSwot.length > 0) {
            setError("Capacidades já foram adicionadas, não é permitido alterar a área.");
            setTimeout(() => {
                setError("");
            }, 6000);
            return;
        } else {
            setSwot({ ...Swot, [event.target.name]: event.target.value })
            setAreaKey(event.target.value)
        }
    }

    const gravidadeDescricao = (valor) => {
        switch (valor) {
            case "1": return 'Sem gravidade';
            case "2": return 'Pouco grave';
            case "3": return 'Grave';
            case "4": return 'Muito grave';
            case "5": return 'Extremamente grave';
        }
    };

    const urgenciaDescricao = (valor) => {
        switch (valor) {
            case "1": return 'Longuíssimo prazo';
            case "2": return 'Longo prazo';
            case "3": return 'Médio prazo';
            case "4": return 'Curto';
            case "5": return 'Imediatamente';
        }
    };

    const tendenciaDescricao = (valor) => {
        switch (valor) {
            case "1": return 'Desaparece';
            case "2": return 'Reduz-se ligeiramente';
            case "3": return 'Permanece';
            case "4": return 'Aumenta';
            case "5": return 'Piora muito';
        }
    };

    const AdicionarForca = () => {
        if (typeof JSON.parse(ForcaKey).key === 'undefined' || JSON.parse(ForcaKey).key === '') {
            setError("Selecione uma capacidade.")
            setTimeout(() => {
                setError("");
            }, 6000);
            return;
        }
        if (ForcaG === 0) {
            setError("Selecione uma gravidade.")
            setTimeout(() => {
                setError("");
            }, 6000);
            return;
        }
        if (ForcaU === 0) {
            setError("Selecione uma urgência.")
            setTimeout(() => {
                setError("");
            }, 6000);
            return;
        }
        if (ForcaT === 0) {
            setError("Selecione uma tendência.")
            setTimeout(() => {
                setError("");
            }, 6000);
            return;
        }
        let data = {
            capacidadeNegocioDemandaKey: JSON.parse(ForcaKey).key,
            gravidade: ForcaG,
            urgencia: ForcaU,
            tendencia: ForcaT,
            gut: ForcaGUT,
            tipo: "Forca",
            titulo: JSON.parse(ForcaKey).titulo
        }
        setForcaSwot((ForcaSwot) => [...ForcaSwot, data])
        setForcaKey("")
        setForcaG(0)
        setForcaU(0)
        setForcaT(0)
        setForcaGUT(0)
    }

    const ExcluirForca = (props) => {
        ForcaSwot.splice(props, 1)
        setForcaKey("")
        setForcaG(0)
        setForcaU(0)
        setForcaT(0)
        setForcaGUT(0)
    }

    const AdicionarFraqueza = () => {
        if (typeof JSON.parse(FraquezaKey).key === 'undefined' || JSON.parse(FraquezaKey).key === '') {
            setError("Selecione uma capacidade.")
            setTimeout(() => {
                setError("");
            }, 6000);
            return;
        }
        if (FraquezaG === 0) {
            setError("Selecione uma gravidade.")
            setTimeout(() => {
                setError("");
            }, 6000);
            return;
        }
        if (FraquezaU === 0) {
            setError("Selecione uma urgência.")
            setTimeout(() => {
                setError("");
            }, 6000);
            return;
        }
        if (FraquezaT === 0) {
            setError("Selecione uma tendência.")
            setTimeout(() => {
                setError("");
            }, 6000);
            return;
        }
        let data = {
            capacidadeNegocioDemandaKey: JSON.parse(FraquezaKey).key,
            gravidade: FraquezaG,
            urgencia: FraquezaU,
            tendencia: FraquezaT,
            gut: FraquezaGUT,
            tipo: "Fraqueza",
            titulo: JSON.parse(FraquezaKey).titulo
        }
        setFraquezaSwot((FraquezaSwot) => [...FraquezaSwot, data])
        setFraquezaKey("")
        setFraquezaG(0)
        setFraquezaU(0)
        setFraquezaT(0)
        setFraquezaGUT(0)
    }

    const ExcluirFraqueza = (props) => {
        FraquezaSwot.splice(props, 1)
        setFraquezaKey("")
        setFraquezaG(0)
        setFraquezaU(0)
        setFraquezaT(0)
        setFraquezaGUT(0)
    }

    const AdicionarOportunidade = () => {
        if (typeof JSON.parse(OportunidadeKey).key === 'undefined' || JSON.parse(OportunidadeKey).key === '') {
            setError("Selecione uma capacidade.")
            setTimeout(() => {
                setError("");
            }, 6000);
            return;
        }
        if (OportunidadeG === 0) {
            setError("Selecione uma gravidade.")
            setTimeout(() => {
                setError("");
            }, 6000);
            return;
        }
        if (OportunidadeU === 0) {
            setError("Selecione uma urgência.")
            setTimeout(() => {
                setError("");
            }, 6000);
            return;
        }
        if (OportunidadeT === 0) {
            setError("Selecione uma tendência.")
            setTimeout(() => {
                setError("");
            }, 6000);
            return;
        }
        let data = {
            capacidadeNegocioDemandaKey: JSON.parse(OportunidadeKey).key,
            gravidade: OportunidadeG,
            urgencia: OportunidadeU,
            tendencia: OportunidadeT,
            gut: OportunidadeGUT,
            tipo: "Oportunidade",
            titulo: JSON.parse(OportunidadeKey).titulo
        }
        setOportunidadeSwot((OportunidadeSwot) => [...OportunidadeSwot, data])
        setOportunidadeKey("")
        setOportunidadeG(0)
        setOportunidadeU(0)
        setOportunidadeT(0)
        setOportunidadeGUT(0)
    }

    const ExcluirOportunidade = (props) => {
        OportunidadeSwot.splice(props, 1)
        setOportunidadeKey("")
        setOportunidadeG(0)
        setOportunidadeU(0)
        setOportunidadeT(0)
        setOportunidadeGUT(0)
    }

    const AdicionarAmeaca = () => {
        if (typeof JSON.parse(AmeacaKey).key === 'undefined' || JSON.parse(AmeacaKey).key === '') {
            setError("Selecione uma capacidade.")
            setTimeout(() => {
                setError("");
            }, 6000);
            return;
        }
        if (AmeacaG === 0) {
            setError("Selecione uma gravidade.")
            setTimeout(() => {
                setError("");
            }, 6000);
            return;
        }
        if (AmeacaU === 0) {
            setError("Selecione uma urgência.")
            setTimeout(() => {
                setError("");
            }, 6000);
            return;
        }
        if (AmeacaT === 0) {
            setError("Selecione uma tendência.")
            setTimeout(() => {
                setError("");
            }, 6000);
            return;
        }
        let data = {
            capacidadeNegocioDemandaKey: JSON.parse(AmeacaKey).key,
            gravidade: AmeacaG,
            urgencia: AmeacaU,
            tendencia: AmeacaT,
            gut: AmeacaGUT,
            tipo: "Ameaca",
            titulo: JSON.parse(OportunidadeKey).titulo
        }
        setAmeacaSwot((AmeacaSwot) => [...AmeacaSwot, data])
        setAmeacaKey("")
        setAmeacaG(0)
        setAmeacaU(0)
        setAmeacaT(0)
        setAmeacaGUT(0)
    }

    const ExcluirAmeaca = (props) => {
        AmeacaSwot.splice(props, 1)
        setAmeacaKey("")
        setAmeacaG(0)
        setAmeacaU(0)
        setAmeacaT(0)
        setAmeacaGUT(0)
    }

    const salvar = () => {
        CriarSwot()
            .then(() => {
                setForcaSwot([])
                setFraquezaSwot([])
                setOportunidadeSwot([])
                setAmeacaSwot([])
            })
    }

    const fecharModal = () => {
        closeModal(false)
        setForcaSwot([])
        setFraquezaSwot([])
        setOportunidadeSwot([])
        setAmeacaSwot([])
    }

    useEffect(() => {
        setForcaGUT(ForcaG * ForcaU * ForcaT);
        setFraquezaGUT(FraquezaG * FraquezaU * FraquezaT)
        setOportunidadeGUT(OportunidadeG * OportunidadeU * OportunidadeT)
        setAmeacaGUT(AmeacaG * AmeacaU * AmeacaT)
    }, [ForcaG, ForcaU, ForcaT, FraquezaG, FraquezaU, FraquezaT, OportunidadeG, OportunidadeU, OportunidadeT, AmeacaG, AmeacaU, AmeacaT]);

    useEffect(() => {
        if (areaKey !== "") {
            ListaCapacidadesPorArea("S", areaKey)
        }
    }, [areaKey])

    return (
        <div className="modal-criar-demanda">
            <div className="modal-criar-demanda-interno">
                <div className="header-modal-criar-demanda">
                    <div>
                        <h1 className="h1-modal">Cadastrar SWOT</h1>
                    </div>
                    <div className="btn-close-modal" onClick={() => fecharModal()}>
                        <FaTimes />
                    </div>
                </div>
                <form>
                    <div className="modal-content-criar-demanda">
                        <Tabs fill className='mt-3 mb-3'>
                            <Tab eventKey="info" title={<span>Informações</span>}>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Descrição</Form.Label>
                                            <Form.Control onChange={handleChange} name="descricao" />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Área</Form.Label>
                                            <Form.Select aria-label="Default select example" value={areaKey} onChange={handleChangeArea} name="areaKey">
                                                <option value="0">Selecione</option>
                                                {Areas?.map((item) => {
                                                    return (
                                                        <option key={item.id} value={item.chave}>
                                                            {item.descricao}
                                                        </option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Ciclo</Form.Label>
                                        <Form.Select aria-label="Default select example" onChange={handleChange} name="cicloDemandaKey">
                                            <option value="0">Selecione</option>
                                            {Ciclos?.map((item) => {
                                                return (
                                                    <option key={item.id} value={item.chave}>
                                                        {item.descricao}
                                                    </option>
                                                )
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                            </Tab>
                            <Tab eventKey="forcas" title={<span>Forças</span>}>
                                <Row>
                                    <Col lg={5}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Capacidade</Form.Label>
                                            <Form.Select aria-label="Default select example" value={ForcaKey} onChange={(e) => setForcaKey(e.target.value)} name="capacidadeNegocioDemandaKey">
                                                <option value="0">Selecione</option>
                                                {CapacidadesPorArea?.map((item) => {
                                                    return (
                                                        <option key={item.id} value={JSON.stringify({ titulo: item.titulo, key: item.chaveCadastro })}>
                                                            {item.titulo}
                                                        </option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Gravidade</Form.Label>
                                            <Form.Select value={ForcaG} onChange={(e) => setForcaG(e.target.value)} name="gravidade">
                                                <option value={0}>Selecione</option>
                                                <option value={1}>Sem gravidade</option>
                                                <option value={2}>Pouco grave</option>
                                                <option value={3}>Grave</option>
                                                <option value={4}>Muito grave</option>
                                                <option value={5}>Extremamente grave</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Urgência</Form.Label>
                                            <Form.Select value={ForcaU} onChange={(e) => setForcaU(e.target.value)} name="urgencia">
                                                <option value={0}>Selecione</option>
                                                <option value={1}>Longuíssimo prazo</option>
                                                <option value={2}>Longo prazo</option>
                                                <option value={3}>Médio prazo</option>
                                                <option value={4}>Curto</option>
                                                <option value={5}>Imediatamente</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Tendência</Form.Label>
                                            <Form.Select value={ForcaT} onChange={(e) => setForcaT(e.target.value)} name="tendencia">
                                                <option value={0}>Selecione</option>
                                                <option value={1}>Desaparece</option>
                                                <option value={2}>Reduz-se ligeiramente</option>
                                                <option value={3}>Permanece</option>
                                                <option value={4}>Aumenta</option>
                                                <option value={5}>Piora muito</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={1}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>GUT</Form.Label>
                                            <Form.Control
                                                readOnly
                                                value={ForcaGUT}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <button type="button" className="btn-dash btn-pesquisar" style={btnPesquisar} onClick={AdicionarForca}><FaPlus style={{ marginRight: "5px" }} />Adicionar força</button>
                                </div>
                                Forças adicionadas
                                <div className="secao-comentario">
                                    {
                                        ForcaSwot?.map((item, index) => {
                                            return (
                                                <div key={index} className="content-comentario">
                                                    <div className='div-comentario'>
                                                        <p className="p-title">Capacidade: </p>
                                                        <p className="p-content" style={{ marginLeft: '5px' }}>{item.titulo}</p>
                                                    </div>
                                                    <div className='div-comentario'>
                                                        <p className="p-content">{gravidadeDescricao(item.gravidade)} | {urgenciaDescricao(item.urgencia)} | {tendenciaDescricao(item.tendencia)}</p>
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <p className="p-title">GUT: </p>
                                                        <p className="p-content" style={{ marginLeft: '5px' }}>{item.gut}</p>
                                                    </div>
                                                    <div className="div-button">
                                                        <button type="button" className="btn-del" onClick={() => ExcluirForca(index)}><TbTrash /></button>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>
                            </Tab>
                            <Tab eventKey="fraquezas" title={<span>Fraquezas</span>}>
                                <Row>
                                    <Col lg={5}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Capacidade</Form.Label>
                                            <Form.Select aria-label="Default select example" value={FraquezaKey} onChange={(e) => setFraquezaKey(e.target.value)} name="capacidadeNegocioDemandaKey">
                                                <option value="0">Selecione</option>
                                                {CapacidadesPorArea?.map((item) => {
                                                    return (
                                                        <option key={item.id} value={JSON.stringify({ titulo: item.titulo, key: item.chaveCadastro })}>
                                                            {item.titulo}
                                                        </option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Gravidade</Form.Label>
                                            <Form.Select value={FraquezaG} onChange={(e) => setFraquezaG(e.target.value)} name="gravidade">
                                                <option value={0}>Selecione</option>
                                                <option value={1}>Sem gravidade</option>
                                                <option value={2}>Pouco grave</option>
                                                <option value={3}>Grave</option>
                                                <option value={4}>Muito grave</option>
                                                <option value={5}>Extremamente grave</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Urgência</Form.Label>
                                            <Form.Select value={FraquezaU} onChange={(e) => setFraquezaU(e.target.value)} name="urgencia">
                                                <option value={0}>Selecione</option>
                                                <option value={1}>Longuíssimo prazo</option>
                                                <option value={2}>Longo prazo</option>
                                                <option value={3}>Médio prazo</option>
                                                <option value={4}>Curto</option>
                                                <option value={5}>Imediatamente</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Tendência</Form.Label>
                                            <Form.Select value={FraquezaT} onChange={(e) => setFraquezaT(e.target.value)} name="tendencia">
                                                <option value={0}>Selecione</option>
                                                <option value={1}>Desaparece</option>
                                                <option value={2}>Reduz-se ligeiramente</option>
                                                <option value={3}>Permanece</option>
                                                <option value={4}>Aumenta</option>
                                                <option value={5}>Piora muito</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={1}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>GUT</Form.Label>
                                            <Form.Control
                                                readOnly
                                                value={FraquezaGUT}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <button type="button" className="btn-dash btn-pesquisar" style={btnPesquisar} onClick={() => AdicionarFraqueza()}><FaPlus style={{ marginRight: "5px" }} />Adicionar fraqueza</button>
                                </div>
                                Fraquezas adicionadas
                                <div className="secao-comentario">
                                    {
                                        FraquezaSwot?.map((item, index) => {
                                            return (
                                                <div key={index} className="content-comentario">
                                                    <div className='div-comentario'>
                                                        <p className="p-title">Capacidade: </p>
                                                        <p className="p-content" style={{ marginLeft: '5px' }}>{item.titulo}</p>
                                                    </div>
                                                    <div className='div-comentario'>
                                                        <p className="p-content">{gravidadeDescricao(item.gravidade)} | {urgenciaDescricao(item.urgencia)} | {tendenciaDescricao(item.tendencia)}</p>
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <p className="p-title">GUT: </p>
                                                        <p className="p-content" style={{ marginLeft: '5px' }}>{item.gut}</p>
                                                    </div>
                                                    <div className="div-button">
                                                        <button type="button" className="btn-del" onClick={() => ExcluirFraqueza(index)}><TbTrash /></button>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>
                            </Tab>
                            <Tab eventKey="oportunidades" title={<span>Oportunidades</span>}>
                                <Row>
                                    <Col lg={5}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Capacidade</Form.Label>
                                            <Form.Select value={OportunidadeKey} aria-label="Default select example" onChange={(e) => setOportunidadeKey(e.target.value)} name="capacidadeNegocioDemandaKey">
                                                <option value="0">Selecione</option>
                                                {CapacidadesPorArea?.map((item) => {
                                                    return (
                                                        <option key={item.id} value={JSON.stringify({ titulo: item.titulo, key: item.chaveCadastro })}>
                                                            {item.titulo}
                                                        </option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Gravidade</Form.Label>
                                            <Form.Select value={OportunidadeG} onChange={(e) => setOportunidadeG(e.target.value)} name="gravidade">
                                                <option value={0}>Selecione</option>
                                                <option value={1}>Sem gravidade</option>
                                                <option value={2}>Pouco grave</option>
                                                <option value={3}>Grave</option>
                                                <option value={4}>Muito grave</option>
                                                <option value={5}>Extremamente grave</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Urgência</Form.Label>
                                            <Form.Select value={OportunidadeU} onChange={(e) => setOportunidadeU(e.target.value)} name="urgencia">
                                                <option value={0}>Selecione</option>
                                                <option value={1}>Longuíssimo prazo</option>
                                                <option value={2}>Longo prazo</option>
                                                <option value={3}>Médio prazo</option>
                                                <option value={4}>Curto</option>
                                                <option value={5}>Imediatamente</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Tendência</Form.Label>
                                            <Form.Select value={OportunidadeT} onChange={(e) => setOportunidadeT(e.target.value)} name="tendencia">
                                                <option value={0}>Selecione</option>
                                                <option value={1}>Desaparece</option>
                                                <option value={2}>Reduz-se ligeiramente</option>
                                                <option value={3}>Permanece</option>
                                                <option value={4}>Aumenta</option>
                                                <option value={5}>Piora muito</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={1}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>GUT</Form.Label>
                                            <Form.Control
                                                readOnly
                                                value={OportunidadeGUT}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <button type="button" className="btn-dash btn-pesquisar" style={btnPesquisar} onClick={() => AdicionarOportunidade()}><FaPlus style={{ marginRight: "5px" }} />Adicionar oportunidade</button>
                                </div>
                                Oportunidades adicionadas
                                <div className="secao-comentario">
                                    {
                                        OportunidadeSwot?.map((item, index) => {
                                            return (
                                                <div key={index} className="content-comentario">
                                                    <div className='div-comentario'>
                                                        <p className="p-title">Capacidade: </p>
                                                        <p className="p-content" style={{ marginLeft: '5px' }}>{item.titulo}</p>
                                                    </div>
                                                    <div className='div-comentario'>
                                                        <p className="p-content">{gravidadeDescricao(item.gravidade)} | {urgenciaDescricao(item.urgencia)} | {tendenciaDescricao(item.tendencia)}</p>
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <p className="p-title">GUT: </p>
                                                        <p className="p-content" style={{ marginLeft: '5px' }}>{item.gut}</p>
                                                    </div>
                                                    <div className="div-button">
                                                        <button type="button" className="btn-del" onClick={() => ExcluirOportunidade(index)}><TbTrash /></button>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>
                            </Tab>
                            <Tab eventKey="ameacas" title={<span>Ameaças</span>}>
                                <Row>
                                    <Col lg={5}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Capacidade</Form.Label>
                                            <Form.Select value={AmeacaKey} aria-label="Default select example" onChange={(e) => setAmeacaKey(e.target.value)} name="capacidadeNegocioDemandaKey">
                                                <option value="0">Selecione</option>
                                                {CapacidadesPorArea?.map((item) => {
                                                    return (
                                                        <option key={item.id} value={JSON.stringify({ titulo: item.titulo, key: item.chaveCadastro })}>
                                                            {item.titulo}
                                                        </option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Gravidade</Form.Label>
                                            <Form.Select value={AmeacaG} onChange={(e) => setAmeacaG(e.target.value)} name="gravidade">
                                                <option value={0}>Selecione</option>
                                                <option value={1}>Sem gravidade</option>
                                                <option value={2}>Pouco grave</option>
                                                <option value={3}>Grave</option>
                                                <option value={4}>Muito grave</option>
                                                <option value={5}>Extremamente grave</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Urgência</Form.Label>
                                            <Form.Select value={AmeacaU} onChange={(e) => setAmeacaU(e.target.value)} name="urgencia">
                                                <option value={0}>Selecione</option>
                                                <option value={1}>Longuíssimo prazo</option>
                                                <option value={2}>Longo prazo</option>
                                                <option value={3}>Médio prazo</option>
                                                <option value={4}>Curto</option>
                                                <option value={5}>Imediatamente</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Tendência</Form.Label>
                                            <Form.Select value={AmeacaT} onChange={(e) => setAmeacaT(e.target.value)} name="tendencia">
                                                <option value={0}>Selecione</option>
                                                <option value={1}>Desaparece</option>
                                                <option value={2}>Reduz-se ligeiramente</option>
                                                <option value={3}>Permanece</option>
                                                <option value={4}>Aumenta</option>
                                                <option value={5}>Piora muito</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={1}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>GUT</Form.Label>
                                            <Form.Control
                                                readOnly
                                                value={AmeacaGUT}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <button type="button" className="btn-dash btn-pesquisar" style={btnPesquisar} onClick={() => AdicionarAmeaca()}><FaPlus style={{ marginRight: "5px" }} />Adicionar ameaça</button>
                                </div>
                                Ameaças adicionadas
                                <div className="secao-comentario">
                                    {
                                        AmeacaSwot?.map((item, index) => {
                                            return (
                                                <div key={index} className="content-comentario">
                                                    <div className='div-comentario'>
                                                        <p className="p-title">Capacidade: </p>
                                                        <p className="p-content" style={{ marginLeft: '5px' }}>{item.titulo}</p>
                                                    </div>
                                                    <div className='div-comentario'>
                                                        <p className="p-content">{gravidadeDescricao(item.gravidade)} | {urgenciaDescricao(item.urgencia)} | {tendenciaDescricao(item.tendencia)}</p>
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <p className="p-title">GUT: </p>
                                                        <p className="p-content" style={{ marginLeft: '5px' }}>{item.gut}</p>
                                                    </div>
                                                    <div className="div-button">
                                                        <button type="button" className="btn-del" onClick={() => ExcluirAmeaca(index)}><TbTrash /></button>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                    <div className="footer-modal-criar-demanda">
                        <button type="button" className="btn-dash btn-ver" style={btnVer} onClick={() => fecharModal()}>Cancelar</button>
                        <button type="button" className="btn-dash btn-criar" style={btnCriar} onClick={() => salvar()}>Salvar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ModalCadastrarSwot