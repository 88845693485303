import '../../App.css';
import React, { useCallback, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ApiContext } from "../../context/ApiContext";
import Header from '../../components/header/Header';
import MenuLateral from '../../components/menu/MenuLateral'
import Footer from '../../components/footer/Footer'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FaPlus } from 'react-icons/fa';
import { IoIosWarning, IoIosCheckmarkCircle } from "react-icons/io";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Toolbar, Sort } from '@syncfusion/ej2-react-grids';
import { L10n } from '@syncfusion/ej2-base';
import ModalCadastrarSwot from '../../components/modal/ModalCadastrarSwot';
import ModalEditarSwot from '../../components/modal/ModalEditarSwot';
import ModalExcluirSwot from '../../components/modal/ModalExcluirSwot';
import ModalRelatorioSwot from '../../components/modal/ModalRelatorioSwot';
import moment from 'moment/moment';
import Cookies from 'js-cookie'
import { TbTrash, TbPencil } from "react-icons/tb";
import { RxDashboard } from "react-icons/rx";
import { StyleContext } from '../../context/StyleContext';

const SWOT = () => {
    const { state } = useLocation()
    const role = Cookies.get('Role')

    const { ObterSwot, setLoading, EditarDemandaModal, setModalEditarDemanda, Success, Error, ExcluirDemandaModal, setModalExcluirDemanda, openModal, setOpenModal, ListaCiclos, loading, CancelarDemandaModal, ConcluirDemandaModal, setCompartilharDemandaModal, CompartilharDemandaModal, ListaSwot, SwotLista, setSwotAtualKey, ListaArea } = useContext(ApiContext)

    const { btnCriar, h3Demanda, corTerciaria } = useContext(StyleContext)

    const sortingOptions = {
        columns: [{ field: 'id', direction: 'Descending' }]
    };

    L10n.load({
        'pt-BR': {
          grid: {
            EmptyRecord: 'Nenhum registro encontrado',
          },
        },
      });

    const CriarSwot = () => {
        setOpenModal(true)
    }

    const selecionarSwot = (props) => {
        ObterSwot(props.chave)
            .then(() => {
                EditarSwot(props)
            })
    }

    const EditarSwot = (props) => {
        setSwotAtualKey(props.chave)
        setModalEditarDemanda(true)
    }

    const ApagarSwot = (props) => {
        setSwotAtualKey(props.chave)
        setModalExcluirDemanda(true)
    }

    const VerRelatorio = (props) => {
        setSwotAtualKey(props.chave)
        setCompartilharDemandaModal(true)
    }

    function carregando() {
        return new Promise(function (resolve) {
            setLoading(true)
            setTimeout(resolve, 1)
        });
    }

    function naoCarregando() {
        return new Promise(function (resolve) {
            setLoading(resolve, false)
        });
    }

    const chamarLista = useCallback(async () => {
        await carregando()
        await ListaSwot()
        await ListaArea()
        await ListaCiclos()
        await naoCarregando()
    })

    useEffect(() => {
        chamarLista()
    }, [])

    useEffect(() => {
        if (openModal || EditarDemandaModal || ExcluirDemandaModal || CancelarDemandaModal || ConcluirDemandaModal || CompartilharDemandaModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [openModal, EditarDemandaModal, ExcluirDemandaModal, CancelarDemandaModal, ConcluirDemandaModal, CompartilharDemandaModal]);

    const gridTemplate = useCallback((props, index) => {
        return (
            <tr className='tr-dem' key={index} >
                <td className="td-dem">{props.descricao}</td>
                <td className="td-dem">{moment(props.dataCriacao).format("DD/MM/YYYY")}</td>
                <td className="td-dem">{props.usuario}</td>
                <td className="td-dem" style={{ textAlign: 'right' }}>
                    <OverlayTrigger
                        placement="left"
                        overlay={<Tooltip id="button-tooltip">
                            Editar SWOT
                        </Tooltip>}
                    ><button className='btn-dem' style={{color: `${corTerciaria}`}} onClick={() => selecionarSwot(props)}><TbPencil /></button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="left"
                        overlay={<Tooltip id="button-tooltip">
                            Ver relatório
                        </Tooltip>}
                    ><button className='btn-dem' style={{color: `${corTerciaria}`}} onClick={() => VerRelatorio(props)}><HiOutlineDocumentReport /></button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="left"
                        overlay={<Tooltip id="button-tooltip">
                            Excluir SWOT
                        </Tooltip>}
                    ><button className='btn-dem' style={{color: `${corTerciaria}`}} onClick={() => ApagarSwot(props)}><TbTrash /></button>
                    </OverlayTrigger>
                </td>
            </tr >);
    }, []);

    return (
        <div>

            {EditarDemandaModal &&
                <ModalEditarSwot />
            }
            {CompartilharDemandaModal &&
                <ModalRelatorioSwot />
            }
            {ExcluirDemandaModal &&
                <ModalExcluirSwot />
            }
            {Success &&
                <div className='success'>
                    <IoIosCheckmarkCircle className='error-icon' />{Success}
                </div>
            }
            {Error &&
                <div className='error'>
                    <IoIosWarning className='error-icon' />{Error}
                </div>
            }
            <Header pagina={'Demandas'}></Header>
            <MenuLateral></MenuLateral>
            <div className="container-dem">
                <div className='header-dem'>
                    {openModal &&
                        <ModalCadastrarSwot closeModal={setOpenModal} />
                    }
                    {(role === "admin" || role === "supervisor") && <button className='btn-dash btn-criar' style={btnCriar} onClick={() => CriarSwot()}><FaPlus style={{ marginRight: "10px" }} />Cadastrar SWOT</button>
                    }
                </div>
                {loading &&
                    <div className="loading-screen">
                        <div className="loader-container">
                            <div className="spinner"></div>
                        </div>
                    </div>
                }
                <div>
                    <h3 className='h3-demanda' style={h3Demanda}>SWOTs cadastrados</h3>
                    <GridComponent locale='pt-BR' id="grid" dataSource={SwotLista} rowTemplate={gridTemplate} sortSettings={sortingOptions} pageSettings={{ pageSize: 50, pageCount: 5 }} allowSorting={true} rowHeight={60} allowPaging={true} >
                        <ColumnsDirective>
                            <ColumnDirective field='descricao' headerText='SWOTs' />
                            <ColumnDirective field='dataCriacao' headerText='Data criação' />
                            <ColumnDirective field='usuario' headerText='Usuário' />
                            <ColumnDirective headerText='Opções' textAlign="right" width={210} />
                        </ColumnsDirective>
                        <Inject services={[Page, Sort, Toolbar]} />
                    </GridComponent>
                </div>
            </div>
            <Footer></Footer>
        </div>
    );
}

export default React.memo(SWOT);