import '../../App.css';
import React, { useContext, useState, useEffect } from "react";
import { ApiContext } from "../../context/ApiContext";
import { Tab, Tabs, Form, Row, Col } from 'react-bootstrap';
import { FaTimes, FaPlus } from 'react-icons/fa';
import { TbTrash } from "react-icons/tb";
import { StyleContext } from '../../context/StyleContext';

function ModalEditarSwot() {
    const { CapacidadesPorArea, ListaCapacidadesPorArea, SwotObter, setSwotObter, setModalEditarDemanda, ListaSwotItem, SwotItemLista, AddSwotItem, setAddSwotItem, AdicionarSwotItem, EditarSwot, ExcluirSwotItem, forcaItem, setForcaItem,
        fraquezaItem, setFraquezaItem,
        oportunidadeItem, setOportunidadeItem,
        ameacaItem, setAmeacaItem, ListaArea, Areas, Ciclos, setError } = useContext(ApiContext)
    const { btnVer, btnCriar, btnPesquisar } = useContext(StyleContext)
    const [areaKey, setAreaKey] = useState(SwotObter?.area.key)

    const handleChange = (event) => {
        if (SwotItemLista.length > 0 && event.target.name === 'areaKey') {
            setError("Capacidades já foram adicionadas, não é permitido alterar a área.");
            setTimeout(() => {
                setError("");
            }, 6000);
            return;
        }
        setSwotObter({ ...SwotObter, [event.target.name]: event.target.value });
        if (event.target.name === 'areaKey') {
            setAreaKey(event.target.value)
        }
    };
    const handleChangeForca = (event) => {
        const updatedSwotItem = { ...forcaItem, [event.target.name]: event.target.value, tipo: "0" };

        if (updatedSwotItem.gravidade && updatedSwotItem.urgencia && updatedSwotItem.tendencia) {
            const gut = updatedSwotItem.gravidade * updatedSwotItem.urgencia * updatedSwotItem.tendencia;
            updatedSwotItem.gut = gut;
        }

        setForcaItem(updatedSwotItem)
        setAddSwotItem(updatedSwotItem);
    };
    const handleChangeFraqueza = (event) => {
        const updatedSwotItem = { ...fraquezaItem, [event.target.name]: event.target.value, tipo: "1" };

        if (updatedSwotItem.gravidade && updatedSwotItem.urgencia && updatedSwotItem.tendencia) {
            const gut = updatedSwotItem.gravidade * updatedSwotItem.urgencia * updatedSwotItem.tendencia;
            updatedSwotItem.gut = gut;
        }
        setFraquezaItem(updatedSwotItem)
        setAddSwotItem(updatedSwotItem);
    };
    const handleChangeOportunidade = (event) => {
        const updatedSwotItem = { ...oportunidadeItem, [event.target.name]: event.target.value, tipo: "2" };

        if (updatedSwotItem.gravidade && updatedSwotItem.urgencia && updatedSwotItem.tendencia) {
            const gut = updatedSwotItem.gravidade * updatedSwotItem.urgencia * updatedSwotItem.tendencia;
            updatedSwotItem.gut = gut;
        }
        setOportunidadeItem(updatedSwotItem)
        setAddSwotItem(updatedSwotItem);
    };
    const handleChangeAmeaca = (event) => {
        const updatedSwotItem = { ...ameacaItem, [event.target.name]: event.target.value, tipo: "3" };

        if (updatedSwotItem.gravidade && updatedSwotItem.urgencia && updatedSwotItem.tendencia) {
            const gut = updatedSwotItem.gravidade * updatedSwotItem.urgencia * updatedSwotItem.tendencia;
            updatedSwotItem.gut = gut;
        }
        setAmeacaItem(updatedSwotItem)
        setAddSwotItem(updatedSwotItem);
    };

    const fecharModal = () => {
        setSwotObter({})
        setAddSwotItem({})
        setModalEditarDemanda(false)
    }

    const chamarLista = async () => {
        ListaSwotItem()
        ListaArea()
    }

    useEffect(() => {
        chamarLista()
    }, []);

    useEffect(() => {
        if (SwotObter.areaKey || SwotObter.area.key !== "") {
            ListaCapacidadesPorArea("S", SwotObter.areaKey || SwotObter.area.key)
        }
    }, [SwotObter.areaKey || SwotObter.area.key])

    return (
        <div className="modal-criar-demanda">
            <div className="modal-criar-demanda-interno">
                <div className="header-modal-criar-demanda">
                    <div>
                        <h1 className="h1-modal">Editar SWOT</h1>
                    </div>
                    <div className="btn-close-modal" onClick={() => fecharModal()}>
                        <FaTimes />
                    </div>
                </div>
                <form>
                    <div className="modal-content-criar-demanda">
                        <Tabs fill className='mt-3 mb-3'>
                            <Tab eventKey="info" title={<span>Informações</span>}>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Descrição</Form.Label>
                                            <Form.Control onChange={handleChange} defaultValue={SwotObter?.descricao} name="descricao" />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Área</Form.Label>
                                            <Form.Select aria-label="Default select example" value={areaKey} onChange={handleChange} name="areaKey">
                                                <option value={0}>Selecione</option>
                                                {Areas?.map((item) => {
                                                    return (
                                                        <option key={item.id} value={item.chave} selected={item.chave == SwotObter?.area.key ? true : false}>
                                                            {item.descricao}
                                                        </option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Ciclo</Form.Label>
                                        <Form.Select aria-label="Default select example" onChange={handleChange} name="cicloDemandaKey">
                                            <option value={0}>Selecione</option>
                                            {Ciclos?.map((item) => {
                                                return (
                                                    <option key={item.id} value={item.chave} selected={item.chave == SwotObter?.cicloDemanda.key ? true : false}>
                                                        {item.descricao}
                                                    </option>
                                                )
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                            </Tab>
                            <Tab eventKey="forcas" title={<span>Forças</span>}>
                                <Row>
                                    <Col lg={5}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Capacidade</Form.Label>
                                            <Form.Select value={forcaItem?.capacidadeNegocioDemandaKey} onChange={handleChangeForca} name="capacidadeNegocioDemandaKey">
                                                <option value={0}>Selecione</option>
                                                {CapacidadesPorArea?.map((item) => {
                                                    return (
                                                        <option key={item.id} value={item.chaveCadastro}>
                                                            {item.titulo}
                                                        </option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Gravidade</Form.Label>
                                            <Form.Select value={forcaItem?.gravidade} onChange={handleChangeForca} name="gravidade">
                                                <option value="">Selecione</option>
                                                <option value="1">Sem gravidade</option>
                                                <option value="2">Pouco grave</option>
                                                <option value="3">Grave</option>
                                                <option value="4">Muito grave</option>
                                                <option value="5">Extremamente grave</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Urgência</Form.Label>
                                            <Form.Select value={forcaItem?.urgencia} onChange={handleChangeForca} name="urgencia">
                                                <option value="">Selecione</option>
                                                <option value="1">Longuíssimo prazo</option>
                                                <option value="2">Longo prazo</option>
                                                <option value="3">Médio prazo</option>
                                                <option value="4">Curto</option>
                                                <option value="5">Imediatamente</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Tendência</Form.Label>
                                            <Form.Select value={forcaItem?.tendencia} onChange={handleChangeForca} name="tendencia">
                                                <option value="">Selecione</option>
                                                <option value="1">Desaparece</option>
                                                <option value="2">Reduz-se ligeiramente</option>
                                                <option value="3">Permanece</option>
                                                <option value="4">Aumenta</option>
                                                <option value="5">Piora muito</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={1}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>GUT</Form.Label>
                                            <Form.Control
                                                readOnly
                                                placeholder='0'
                                                value={forcaItem?.gut}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <button type="button" className="btn-dash btn-pesquisar" style={btnPesquisar} onClick={() => AdicionarSwotItem()}><FaPlus style={{ marginRight: "5px" }} />Adicionar força</button>
                                </div>
                                Forças adicionadas
                                <div className="secao-comentario">
                                    {
                                        SwotItemLista?.map((item, index) => {
                                            return (
                                                item.tipo === 0 &&
                                                <div key={index} className="content-comentario">
                                                    <div className='div-comentario'>
                                                        <p className="p-title">Forças:</p>
                                                        <p className="p-content" style={{ marginLeft: '5px' }}>{item.capacidadeNegocioDemanda.titulo}</p>
                                                    </div>
                                                    <div className='div-comentario'>
                                                        <p className="p-content">{(item.gravidade)} | {item.urgencia} | {item.tendencia}</p>
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <p className="p-title">GUT: </p>
                                                        <p className="p-content" style={{ marginLeft: '5px' }}>{item.gut}</p>
                                                    </div>
                                                    <div className="div-button">
                                                        <button type="button" className="btn-del" onClick={() => ExcluirSwotItem(item.key)}><TbTrash /></button>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>
                            </Tab>
                            <Tab eventKey="fraquezas" title={<span>Fraquezas</span>}>
                                <Row>
                                    <Col lg={5}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Capacidade</Form.Label>
                                            <Form.Select value={fraquezaItem?.capacidadeNegocioDemandaKey} onChange={handleChangeFraqueza} name="capacidadeNegocioDemandaKey">
                                                <option value="0">Selecione</option>
                                                {CapacidadesPorArea?.map((item) => {
                                                    return (
                                                        <option key={item.id} value={item.chaveCadastro}>
                                                            {item.titulo}
                                                        </option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Gravidade</Form.Label>
                                            <Form.Select value={fraquezaItem?.gravidade} onChange={handleChangeFraqueza} name="gravidade">
                                                <option value={0}>Selecione</option>
                                                <option value={1}>Sem gravidade</option>
                                                <option value={2}>Pouco grave</option>
                                                <option value={3}>Grave</option>
                                                <option value={4}>Muito grave</option>
                                                <option value={5}>Extremamente grave</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Urgência</Form.Label>
                                            <Form.Select value={fraquezaItem?.urgencia} onChange={handleChangeFraqueza} name="urgencia">
                                                <option value={0}>Selecione</option>
                                                <option value={1}>Longuíssimo prazo</option>
                                                <option value={2}>Longo prazo</option>
                                                <option value={3}>Médio prazo</option>
                                                <option value={4}>Curto</option>
                                                <option value={5}>Imediatamente</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Tendência</Form.Label>
                                            <Form.Select value={fraquezaItem?.tendencia} onChange={handleChangeFraqueza} name="tendencia">
                                                <option value={0}>Selecione</option>
                                                <option value={1}>Desaparece</option>
                                                <option value={2}>Reduz-se ligeiramente</option>
                                                <option value={3}>Permanece</option>
                                                <option value={4}>Aumenta</option>
                                                <option value={5}>Piora muito</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={1}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>GUT</Form.Label>
                                            <Form.Control
                                                readOnly
                                                value={fraquezaItem?.gut}
                                                placeholder='0'
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <button type="button" className="btn-dash btn-pesquisar" style={btnPesquisar} onClick={() => AdicionarSwotItem()}><FaPlus style={{ marginRight: "5px" }} />Adicionar fraqueza</button>
                                </div>
                                Fraquezas adicionadas
                                <div className="secao-comentario">
                                    {
                                        SwotItemLista?.map((item, index) => {
                                            return (
                                                item.tipo === 1 &&
                                                <div key={index} className="content-comentario">
                                                    <div className='div-comentario'>
                                                        <p className="p-title">Fraquezas: </p>
                                                        <p className="p-content" style={{ marginLeft: '5px' }}>{item.capacidadeNegocioDemanda.titulo}</p>
                                                    </div>
                                                    <div className='div-comentario'>
                                                        <p className="p-content">{(item.gravidade)} | {item.urgencia} | {item.tendencia}</p>
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <p className="p-title">GUT: </p>
                                                        <p className="p-content" style={{ marginLeft: '5px' }}>{item.gut}</p>
                                                    </div>
                                                    <div className="div-button">
                                                        <button type="button" className="btn-del" onClick={() => ExcluirSwotItem(item.key)}><TbTrash /></button>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>
                            </Tab>
                            <Tab eventKey="oportunidades" title={<span>Oportunidades</span>}>
                                <Row>
                                    <Col lg={5}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Capacidade</Form.Label>
                                            <Form.Select value={oportunidadeItem?.capacidadeNegocioDemandaKey} onChange={handleChangeOportunidade} name="capacidadeNegocioDemandaKey">
                                                <option value="0">Selecione</option>
                                                {CapacidadesPorArea?.map((item) => {
                                                    return (
                                                        <option key={item.id} value={item.chaveCadastro}>
                                                            {item.titulo}
                                                        </option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Gravidade</Form.Label>
                                            <Form.Select value={oportunidadeItem?.gravidade} onChange={handleChangeOportunidade} name="gravidade">
                                                <option value={0}>Selecione</option>
                                                <option value={1}>Sem gravidade</option>
                                                <option value={2}>Pouco grave</option>
                                                <option value={3}>Grave</option>
                                                <option value={4}>Muito grave</option>
                                                <option value={5}>Extremamente grave</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Urgência</Form.Label>
                                            <Form.Select value={oportunidadeItem?.urgencia} onChange={handleChangeOportunidade} name="urgencia">
                                                <option value={0}>Selecione</option>
                                                <option value={1}>Longuíssimo prazo</option>
                                                <option value={2}>Longo prazo</option>
                                                <option value={3}>Médio prazo</option>
                                                <option value={4}>Curto</option>
                                                <option value={5}>Imediatamente</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Tendência</Form.Label>
                                            <Form.Select value={oportunidadeItem?.tendencia} onChange={handleChangeOportunidade} name="tendencia">
                                                <option value={0}>Selecione</option>
                                                <option value={1}>Desaparece</option>
                                                <option value={2}>Reduz-se ligeiramente</option>
                                                <option value={3}>Permanece</option>
                                                <option value={4}>Aumenta</option>
                                                <option value={5}>Piora muito</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={1}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>GUT</Form.Label>
                                            <Form.Control
                                                placeholder='0'
                                                readOnly
                                                value={oportunidadeItem?.gut}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <button type="button" className="btn-dash btn-pesquisar" style={btnPesquisar} onClick={() => AdicionarSwotItem()}><FaPlus style={{ marginRight: "5px" }} />Adicionar oportunidade</button>
                                </div>
                                Oportunidades adicionadas
                                <div className="secao-comentario">
                                    {
                                        SwotItemLista?.map((item, index) => {
                                            return (
                                                item.tipo === 2 &&
                                                <div key={index} className="content-comentario">
                                                    <div className='div-comentario'>
                                                        <p className="p-title">Oportunidades: </p>
                                                        <p className="p-content" style={{ marginLeft: '5px' }}>{item.capacidadeNegocioDemanda.titulo}</p>
                                                    </div>
                                                    <div className='div-comentario'>
                                                        <p className="p-content">{(item.gravidade)} | {item.urgencia} | {item.tendencia}</p>
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <p className="p-title">GUT: </p>
                                                        <p className="p-content" style={{ marginLeft: '5px' }}>{item.gut}</p>
                                                    </div>
                                                    <div className="div-button">
                                                        <button type="button" className="btn-del" onClick={() => ExcluirSwotItem(item.key)}><TbTrash /></button>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>
                            </Tab>
                            <Tab eventKey="ameacas" title={<span>Ameaças</span>}>
                                <Row>
                                    <Col lg={5}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Capacidade</Form.Label>
                                            <Form.Select value={ameacaItem?.capacidadeNegocioDemandaKey} onChange={handleChangeAmeaca} name="capacidadeNegocioDemandaKey">
                                                <option value="0">Selecione</option>
                                                {CapacidadesPorArea?.map((item) => {
                                                    return (
                                                        <option key={item.id} value={item.chaveCadastro}>
                                                            {item.titulo}
                                                        </option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Gravidade</Form.Label>
                                            <Form.Select value={ameacaItem?.gravidade} onChange={handleChangeAmeaca} name="gravidade">
                                                <option value={0}>Selecione</option>
                                                <option value={1}>Sem gravidade</option>
                                                <option value={2}>Pouco grave</option>
                                                <option value={3}>Grave</option>
                                                <option value={4}>Muito grave</option>
                                                <option value={5}>Extremamente grave</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Urgência</Form.Label>
                                            <Form.Select value={ameacaItem?.urgencia} onChange={handleChangeAmeaca} name="urgencia">
                                                <option value={0}>Selecione</option>
                                                <option value={1}>Longuíssimo prazo</option>
                                                <option value={2}>Longo prazo</option>
                                                <option value={3}>Médio prazo</option>
                                                <option value={4}>Curto</option>
                                                <option value={5}>Imediatamente</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Tendência</Form.Label>
                                            <Form.Select value={ameacaItem?.tendencia} onChange={handleChangeAmeaca} name="tendencia">
                                                <option value={0}>Selecione</option>
                                                <option value={1}>Desaparece</option>
                                                <option value={2}>Reduz-se ligeiramente</option>
                                                <option value={3}>Permanece</option>
                                                <option value={4}>Aumenta</option>
                                                <option value={5}>Piora muito</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={1}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>GUT</Form.Label>
                                            <Form.Control
                                                readOnly
                                                value={ameacaItem?.gut}
                                                placeholder='0'
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <button type="button" className="btn-dash btn-pesquisar" style={btnPesquisar} onClick={() => AdicionarSwotItem()}><FaPlus style={{ marginRight: "5px" }} />Adicionar ameaça</button>
                                </div>
                                Ameaças adicionadas
                                <div className="secao-comentario">
                                    {
                                        SwotItemLista?.map((item, index) => {
                                            return (
                                                item.tipo === 3 &&
                                                <div key={index} className="content-comentario">
                                                    <div className='div-comentario'>
                                                        <p className="p-title">Ameaças: </p>
                                                        <p className="p-content" style={{ marginLeft: '5px' }}>{item.capacidadeNegocioDemanda.titulo}</p>
                                                    </div>
                                                    <div className='div-comentario'>
                                                        <p className="p-content">{(item.gravidade)} | {item.urgencia} | {item.tendencia}</p>
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <p className="p-title">GUT: </p>
                                                        <p className="p-content" style={{ marginLeft: '5px' }}>{item.gut}</p>
                                                    </div>
                                                    <div className="div-button">
                                                        <button type="button" className="btn-del" onClick={() => ExcluirSwotItem(item.key)}><TbTrash /></button>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                    <div className="footer-modal-criar-demanda">
                        <button type="button" className="btn-dash btn-ver" style={btnVer} onClick={() => fecharModal()}>Cancelar</button>
                        <button type="button" className="btn-dash btn-criar" style={btnCriar} onClick={() => EditarSwot()}>Salvar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ModalEditarSwot