import '../../App.css';
import React, { useContext, useEffect, useState } from 'react';
import Header from '../../components/header/Header';
import MenuLateral from '../../components/menu/MenuLateral'
import Footer from '../../components/footer/Footer'
import { FormControl, useScrollTrigger } from '@mui/material'
import { Form, Col, Row, Popover, OverlayTrigger, Tab, Tabs } from 'react-bootstrap'
import { MdRefresh } from 'react-icons/md'
import { ApiContext } from '../../context/ApiContext';
import { AccumulationChartComponent, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective, Inject, PieSeries, AccumulationTooltip, AccumulationDataLabel } from '@syncfusion/ej2-react-charts';
import { IoIosWarning, IoIosCheckmarkCircle, IoMdTennisball } from "react-icons/io";
import { StyleContext } from '../../context/StyleContext';

function BSC({ BSCkey, naoAlterarBSCSwot }) {
    const { ObterBSC, DiretrizEstrategica, Prioridades, ResumoPrioridades, SimularBSC, BSCDados, loading, SalvarBSC, Success, Error, setError, show, setShow, ListaCiclos, Ciclos } = useContext(ApiContext)
    const { btnVer, btnCriar } = useContext(StyleContext)

    const [foco, setFoco] = useState(false)

    let arrayD = []
    DiretrizEstrategica?.map((a) => {
        return (
            a.itens.map((b) => {
                arrayD.push(b)
            })
        )
    })

    const cor = (descricao) => {
        switch (descricao) {
            case "Normal":
                return "input-green"
            case "Importante":
                return "input-yellow"
            case "Prioritária":
                return "input-red"
            default:
                break;
        }
    }

    const corInput = (descricao) => {
        switch (descricao) {
            case 'Normal':
                return "input-green"
            case 'Importante':
                return "input-yellow"
            case 'Prioritária':
                return "input-red"
            default:
                break;
        }
    }

    const retornaDescricao = (descricao) => {
        switch (descricao) {
            case "PERSPECTIVA FINANCEIRA":
                return "Financeira"
            case "PERSPECTIVA COOPERADO & CLIENTE":
                return "Cooperado e cliente"
            case "PERSPECTIVA INTERNA":
                return "Interna"
            case "APRENDIZADO & CRESCIMENTO":
                return "Aprendizado e crescimento"
            default:
                break;
        }
    }
    const retornaCor = (descricao) => {
        switch (descricao) {
            case "PERSPECTIVA FINANCEIRA":
                return "#BFDE94"
            case "PERSPECTIVA COOPERADO & CLIENTE":
                return "#9FCB61"
            case "PERSPECTIVA INTERNA":
                return "#388933"
            case "APRENDIZADO & CRESCIMENTO":
                return "#0F6631"
            default:
                break;
        }
    }

    const mudarPorcentagemBsc = (v, i) => {
        const texto = parseInt(v.target.value);

        if (texto > 100) {
            setError('Valor não pode ser maior que 100%')
            setTimeout(() => {
                setError("");
            }, 6000);
            return;
        } else if (texto < 0) {
            setError('Valor não pode ser negativo.')
            setTimeout(() => {
                setError("");
            }, 6000);
            return;
        }
        setShow(true)
        try {
            BSCDados.diretrizEstrategica.forEach((item, index) => {
                if (index == i) {
                    item.pesoPorcentagem = parseInt(v.target.value)
                }
            })
        }
        catch (ex) {
        }
        finally {
            SimularBSC(BSCkey)
        }
    };

    const mudarPrioridadeBsc = (v, key) => {
        try {
            BSCDados.diretrizEstrategicaItems.forEach((item) => {
                if (key == item.key) {
                    item.diretrizEstrategicaPrioridadeId = parseInt(v.target.value)
                }
            })
        }
        catch (ex) {
        }
        finally {
            SimularBSC(BSCkey)
        }

    };

    const onPointRender = (args) => {
        if (args.point.x.indexOf('Normal') > -1) {
            args.fill = '#16A34A';
        } else if (args.point.x.indexOf('Importante') > -1) {
            args.fill = '#F87171';
        } else if (args.point.x.indexOf('Prioritária') > -1) {
            args.fill = '#FACC15';
        }
        else {
            args.fill = '#7f84e8';
        }
    };

    const onPointRender2 = (args) => {
        if (args.point.x == 'PERSPECTIVA FINANCEIRA') {
            args.fill = '#BFDE94';
        } else if (args.point.x == 'PERSPECTIVA COOPERADO & CLIENTE') {
            args.fill = '#9FCB61';
        } else if (args.point.x == 'PERSPECTIVA INTERNA') {
            args.fill = '#388933';
        } else if (args.point.x == 'APRENDIZADO & CRESCIMENTO') {
            args.fill = '#0F6631';
        } else {
            args.fill = '#7f84e8';
        }
    };

    const focus = () => {
        setShow(false)
        setFoco(true)
    }

    const salvar = () => {
        SalvarBSC(BSCDados.cicloDemandaKey)
        setFoco(false)
    }

    useEffect(() => {
        ListaCiclos()
        if (BSCkey) {
            setFoco(false)
            ObterBSC(BSCkey)
        }
    }, [BSCkey])

    return (
        <>
            <Header pagina={'BSC'}></Header>
            <MenuLateral></MenuLateral>
            {Success &&
                <div className='success'>
                    <IoIosCheckmarkCircle className='error-icon' />{Success}
                </div>
            }
            {Error &&
                <div className='error'>
                    <IoIosWarning className='error-icon' />{Error}
                </div>
            }
            <div className="container-pages">
                {loading &&
                    <div className="loading-screen">
                        <div className="loader-container">
                            <div className="spinner"></div>
                        </div>
                    </div>
                }
                <div className='row'>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '25px' }}>
                            <Col lg={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Ciclo</Form.Label>
                                    <Form.Control defaultValue={BSCDados?.cicloDemanda?.descricao} readOnly />
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Nome do BSC</Form.Label>
                                    <Form.Control defaultValue={BSCDados?.descricao} readOnly />
                                </Form.Group>
                            </Col>
                        </div>
                        {BSCkey &&
                            (show &&
                                <button className='btn-dash btn-criar mt-3' style={btnCriar} onClick={() => salvar()}>Salvar</button>
                            )
                        }
                    </div>
                    <div className='col-lg-5 container'>
                        <div>
                            <div className="col-md-12 section-bsc-grafico">
                                <div className="content-prioridade">
                                    <h2 className="h2-bsc"><span style={{ fontWeight: '500' }}>Impacto Objetivos de Negócio</span></h2>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'start', marginTop: '10px', padding: '0 20px' }}>
                                        <AccumulationChartComponent id='bsc'
                                            pointRender={onPointRender}
                                            tooltip={{ enable: true }}
                                            width='700'
                                            height='270'
                                            center={{ x: '50%', y: '35%' }}
                                        >
                                            <Inject services={[PieSeries, AccumulationTooltip, AccumulationDataLabel]} />
                                            <AccumulationSeriesCollectionDirective>
                                                <AccumulationSeriesDirective
                                                    dataSource={ResumoPrioridades}
                                                    yName='y'
                                                    xName='x'
                                                    type='Pie'
                                                    pointColorMapping='fill'
                                                    border={{ width: "5px" }}
                                                    innerRadius='60%'
                                                    dataLabel={{ visible: true, position: 'Outside', name: 'text', font: { fontWeight: '600' }, connectorStyle: { type: 'Curve' } }}
                                                >
                                                </AccumulationSeriesDirective>
                                            </AccumulationSeriesCollectionDirective>
                                        </AccumulationChartComponent>
                                        <div style={{ marginTop: '-75px' }} className='span-bsc'>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div className='status-concluido' /> Normal
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span className='status-prioritario'></span> Prioritária
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span className='status-atraso'></span> Importante
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='section-bsc-grafico'>
                                <h2 className="h2-bsc"><span style={{ fontWeight: '500' }}>Perspectivas</span></h2>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'start', marginTop: '10px', padding: '0 20px' }}>
                                    <AccumulationChartComponent id='teste'
                                        pointRender={onPointRender2}
                                        tooltip={{ enable: true }}
                                        width='700'
                                        height='270'
                                        center={{ x: '50%', y: '36%' }}
                                    >
                                        <Inject services={[PieSeries, AccumulationTooltip, AccumulationDataLabel]} />
                                        <AccumulationSeriesCollectionDirective>
                                            <AccumulationSeriesDirective
                                                dataSource={DiretrizEstrategica}
                                                yName='pesoPorcentagem'
                                                xName='descricao'
                                                type='Pie'
                                                pointColorMapping='fill'
                                            >
                                            </AccumulationSeriesDirective>
                                        </AccumulationSeriesCollectionDirective>
                                    </AccumulationChartComponent>
                                    <div style={{ marginTop: '-55px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                        <div className='row'>
                                            {DiretrizEstrategica?.map((item) => {
                                                return (
                                                    <div style={{ display: 'flex', alignItems: 'center' }} className='col-lg-6'>
                                                        <div style={{ width: '12px', height: '12px', marginRight: '8px', backgroundColor: retornaCor(item.descricao), borderRadius: '2px' }} /> <span style={{ marginRight: '10px', color: '#A1A1AA' }}>{item.pesoPorcentagem}%</span>

                                                        <span className='span-bsc'> {retornaDescricao(item.descricao)}</span>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-7 container'>
                        <div className='row'>
                            <Tabs className='nav-tabs-bsc' fill>
                                {DiretrizEstrategica?.map((item, index) => {
                                    return (
                                        <Tab eventKey={item.descricao} title={
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>{retornaDescricao(item.descricao)}
                                                <div style={{ display: 'flex', alignItems: 'center' }} className='content-value-bsc'>
                                                    <input
                                                        readOnly={naoAlterarBSCSwot}
                                                        type='number'
                                                        min="0"
                                                        max="100"
                                                        className="value-bsc"
                                                        {...(foco ? { defaultValue: item.pesoPorcentagem } : { value: item.pesoPorcentagem })}

                                                        onFocus={() => focus()}
                                                        onBlur={(v) => mudarPorcentagemBsc(v, index)} /><span className='porcentagem' style={{ fontSize: '16px' }}>%</span>
                                                </div>
                                            </div>}
                                        >
                                            <div key={index}>
                                                <div className="section-novo-bsc">
                                                    <div className="content-bsc">
                                                        <table style={{ width: "100%" }} >
                                                            <thead>
                                                                <tr style={{ fontSize: '14px', height: '21px' }}>
                                                                    <th className='th-bsc'>Ordem</th>
                                                                    <th className='th-bsc'>Descrição</th>
                                                                    <th className='th-bsc' style={{ textAlign: 'end' }}>Impacto</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                {item.itens?.map((a, index) => {
                                                                    return (
                                                                        <tr className="tr-bsc" key={index}>
                                                                            <td className='td-bsc'>{a.ordem}º</td>
                                                                            <td className='td-bsc'>{a.letra} - {a.descricao}<OverlayTrigger placement="right" overlay={<Popover id="popover-basic">
                                                                                <Popover.Header as="h3">Informações</Popover.Header>
                                                                                <Popover.Body>
                                                                                    {a.descricaoCompleta.replace(/(?:\\[rn])+/g, " ")}
                                                                                </Popover.Body>
                                                                            </Popover>} >
                                                                                <span className='tooltip-bsc'>?</span>
                                                                            </OverlayTrigger ></td>
                                                                            <td className='td-bsc' style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                                                                                <Form.Select
                                                                                    id={corInput(a?.diretrizEstrategicaPrioridade?.descricao)}
                                                                                    name='selectPrioridade'
                                                                                    as="select"
                                                                                    style={{ padding: "2px 5px" }}
                                                                                    onChange={(v) => mudarPrioridadeBsc(v, a.key)}
                                                                                    disabled={naoAlterarBSCSwot}
                                                                                >
                                                                                    {Prioridades?.map((p, index) => {
                                                                                        return (
                                                                                            <option
                                                                                                className={cor(p.descricao)}
                                                                                                key={index}
                                                                                                value={p.id}
                                                                                                selected={p.id === a.diretrizEstrategicaPrioridadeId ? true : false}
                                                                                            >
                                                                                                {p.descricao}
                                                                                            </option>
                                                                                        )
                                                                                    })}
                                                                                </Form.Select>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                    )
                                })}
                            </Tabs>
                        </div>
                        <div className="col-md-12 section-bsc">
                            <div className="content-prioridade">
                                <div className="header-prioridade">
                                    <h2 className="h2-bsc"><span style={{ fontWeight: '500' }}>Ordem de Prioridade</span></h2>
                                </div>
                                <table style={{ width: '100%' }}>
                                    <tbody>
                                        {
                                            arrayD?.sort((a, b) => a.ordem - b.ordem).map((o, index) => {
                                                return (
                                                    <tr key={index} className="tr-bsc">
                                                        <td className='td-bsc-ordem'>
                                                            <div className='ordem'>
                                                                <div>{o.ordem}º</div>
                                                                <div>{o.letra}</div>
                                                            </div>
                                                            <div>{o.descricao}</div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}

export default BSC;