import '../../App.css';
import React, { useContext, useEffect, useState, useRef } from "react";
import { ApiContext } from "../../context/ApiContext";
import { Tab, Tabs, Row, Col, ProgressBar } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { IoChevronDownOutline, IoChevronUp } from "react-icons/io5";
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, AreaSeries, PolarSeries, RadarSeries, Highlight, Tooltip } from '@syncfusion/ej2-react-charts';
import { Browser } from '@syncfusion/ej2-base';

function ModalRelatorioSwot() {
    const { CadastrarEmpresa, ListaRelatorio, SwotRelatorio, setCompartilharDemandaModal, ListaMatriz, SwotMatriz } = useContext(ApiContext)

    let soma = SwotRelatorio?.quantidadeForca + SwotRelatorio?.quantidadeFraqueza + SwotRelatorio?.quantidadeOportunidade + SwotRelatorio?.quantidadeAmeaca

    let data = [
        { x: 'Forças', y: SwotRelatorio?.quantidadeForca },
        { x: 'Fraquezas', y: SwotRelatorio?.quantidadeFraqueza },
        { x: 'Oportunidades', y: SwotRelatorio?.quantidadeOportunidade },
        { x: 'Ameaças', y: SwotRelatorio?.quantidadeAmeaca },
    ]

    useEffect(() => {
        ListaRelatorio()
        ListaMatriz()
    }, [])

    const [type, setType] = useState('Polar');
    let chartInstance = useRef(null);
    const onChartLoad = (args) => {
        document.getElementById('charts').setAttribute('title', '');
    };
    const load = (args) => {
    };
    const cor = (valor) => {
        if (valor >= -200 && valor <= -100) {
            return "indice indice-red";
        } else if (valor > -100 && valor < 100) {
            return "indice indice-yellow";
        } else if (valor >= 100 && valor <= 200) {
            return "indice indice-green";
        }
    }

    return (
        <div className="modal-nova">
            <div className="modal-nova-interno">
                <div className="header-modal-nova">
                    <div>
                        <h1 className="h1-modal">Relatório SWOT - {SwotRelatorio?.descricao}</h1>
                    </div>
                    <div className="btn-close-modal" onClick={() => setCompartilharDemandaModal(false)}>
                        <FaTimes />
                    </div>
                </div>
                <form className='form-modal-nova' onSubmit={CadastrarEmpresa}>
                    <div>
                        <Tabs fill className='mb-3' style={{ margin: '0 40px' }}>
                            <Tab eventKey="indice" title="Relatórios">
                                {SwotRelatorio &&
                                    <>
                                        <div className='modal-swot'>
                                            <h1 className="h1-modal" style={{ borderBottom: '1px solid #e4e4e7', paddingLeft: '20px' }}>Índice de favorabilidade</h1>
                                            <div className='modal-body-swot'>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <div className={cor(SwotRelatorio?.indice.toFixed(2))}>
                                                        <p>Seu índice</p>
                                                        <span>{SwotRelatorio?.indice.toFixed(2)} %</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderTop: '1px solid #E4E4E7' }}>
                                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px 0', borderRight: '1px solid #e4e4e7', gap: '10px' }}>
                                                    <span>Muito desfavorável</span>
                                                    <span className='span-swot span-swot-red'> <IoChevronDownOutline /> -100%</span>
                                                </div>
                                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px 0', borderRight: '1px solid #e4e4e7', gap: '10px' }}>
                                                    <span>Desfavorável</span>
                                                    <span className='span-swot span-swot-red'>-100%</span>
                                                </div>
                                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px 0', borderRight: '1px solid #e4e4e7', gap: '10px' }}>
                                                    <span>Neutro</span>
                                                    <span className='span-swot span-swot-yellow'>0%</span>
                                                </div>
                                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px 0', borderRight: '1px solid #e4e4e7', gap: '10px' }}>
                                                    <span>Favorável</span>
                                                    <span className='span-swot span-swot-green'>100%</span>
                                                </div>
                                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px 0', gap: '10px' }}>
                                                    <span>Muito favorável</span>
                                                    <span className='span-swot span-swot-green'><IoChevronUp />100%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                <div style={{ marginTop: '25px', display: 'flex', justifyContent: 'space-between', alignItems: 'start', height: '400px' }}>
                                    <div className='modal-swot' style={{ width: '100%', height: '100%' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '20px', borderBottom: '1px solid #e4e4e7' }}>
                                            <h1 className="h1-modal" style={{ paddingLeft: '20px' }}>Análise Geral</h1>
                                            <span style={{ fontSize: '14px' }} className='span-cinza'>Fatores internos e externos</span>
                                        </div>
                                        {SwotRelatorio &&
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', gap: '25px' }} className='modal-body-swot'>
                                                <div style={{ width: '100%' }}>
                                                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
                                                        <span style={{ fontWeight: 600 }}>Forças</span>
                                                        <span>{SwotRelatorio?.quantidadeForca}</span>
                                                    </div>
                                                    <ProgressBar min={0} max={soma} id='progress-forca' now={SwotRelatorio?.quantidadeForca} style={{ width: '100%' }} />
                                                </div>
                                                <div style={{ width: '100%' }}>
                                                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
                                                        <span style={{ fontWeight: 600 }}>Fraquezas</span>
                                                        <span>{SwotRelatorio.quantidadeFraqueza}</span>
                                                    </div>
                                                    <ProgressBar min={0} max={soma} id='progress-fraqueza' now={SwotRelatorio?.quantidadeFraqueza} style={{ width: '100%' }} />
                                                </div>
                                                <div style={{ width: '100%' }}>
                                                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
                                                        <span style={{ fontWeight: 600 }}>Oportunidades</span>
                                                        <span>{SwotRelatorio.quantidadeOportunidade}</span>
                                                    </div>
                                                    <ProgressBar min={0} max={soma} id='progress-oportunidade' now={SwotRelatorio?.quantidadeOportunidade} style={{ width: '100%' }} />
                                                </div>
                                                <div style={{ width: '100%', height: '100%' }}>
                                                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
                                                        <span style={{ fontWeight: 600 }}>Ameaças</span>
                                                        <span>{SwotRelatorio?.quantidadeAmeaca}</span>
                                                    </div>
                                                    <ProgressBar min={0} max={soma} id='progress-ameaca' now={SwotRelatorio?.quantidadeAmeaca} style={{ width: '100%' }} />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className='modal-swot' style={{ width: '100%', height: '100%' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '20px', borderBottom: '1px solid #e4e4e7' }}>
                                            <h1 className="h1-modal" style={{ paddingLeft: '20px' }}>Radar da Análise</h1>
                                            <span style={{ fontSize: '14px' }} className='span-cinza'>SWOT Capacidades</span>
                                        </div>
                                        <div className='modal-body-swot'>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                                <ChartComponent id='charts' ref={chartInstance} primaryXAxis={{ valueType: 'Category', labelPlacement: 'OnTicks', interval: 1, coefficient: Browser.isDevice ? 80 : 100 }} legendSettings={{ visible: true, enableHighlight: true }} tooltip={{ enable: true }} loaded={onChartLoad.bind(this)} width='380' height='300'>
                                                    <Inject services={[AreaSeries, Legend, Category, PolarSeries, RadarSeries, Highlight, Tooltip]} />
                                                    <SeriesCollectionDirective>
                                                        <SeriesDirective dataSource={data} xName='x' yName='y' width={2} opacity={0.5} type={type} drawType='Area' border={{ color: 'transparent' }} />
                                                    </SeriesCollectionDirective>
                                                </ChartComponent>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-swot" style={{ marginTop: '25px' }}>
                                    <div className="modal-body-swot" style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', gap: '25px' }}>
                                        <div style={{ border: '1px solid #e4e4e7', borderRadius: '8px', width: '100%' }}>
                                            <div style={{ padding: '16px', borderBottom: '1px solid #e4e4e7' }}>
                                                <span className="span-swot span-swot-green">Análise das forças</span>
                                            </div>
                                            <div className="modal-body-swot" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                {SwotRelatorio && SwotRelatorio?.forcas.length > 0 ?
                                                    SwotRelatorio?.forcas?.map((item, index) => {
                                                        return (
                                                            <div style={{ width: '100%' }} key={index}>
                                                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
                                                                    <span style={{ fontWeight: 600 }}>{item.capacidadeNegocioDemanda.titulo}</span>
                                                                    <span>{item.gut}</span>
                                                                </div>
                                                                <ProgressBar min={0} max={125} id='progress-forca' now={item.gut} style={{ width: '100%' }} />
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <p>Não foram adicionadas forças a esse SWOT.</p>
                                                }
                                            </div>
                                        </div>
                                        <div style={{ border: '1px solid #e4e4e7', borderRadius: '8px', width: '100%' }}>
                                            <div style={{ padding: '16px', borderBottom: '1px solid #e4e4e7' }}>
                                                <span className="span-swot span-swot-red">Análise das fraquezas</span>
                                            </div>
                                            <div className="modal-body-swot" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                {SwotRelatorio && SwotRelatorio?.fraquezas.length > 0 ?
                                                    SwotRelatorio?.fraquezas?.map((item, index) => {
                                                        return (
                                                            <div style={{ width: '100%' }} key={index}>
                                                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
                                                                    <span style={{ fontWeight: 600 }}>{item.capacidadeNegocioDemanda.titulo ? item.capacidadeNegocioDemanda.titulo : '-'}</span>
                                                                    <span>{item.gut}</span>
                                                                </div>
                                                                <ProgressBar min={0} max={125} id='progress-fraqueza' now={item.gut} style={{ width: '100%' }} />
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <p>Não foram adicionadas fraquezas a esse SWOT.</p>
                                                }
                                            </div>
                                        </div>
                                        <div style={{ border: '1px solid #e4e4e7', borderRadius: '8px', width: '100%' }}>
                                            <div style={{ padding: '16px', borderBottom: '1px solid #e4e4e7' }}>
                                                <span className="span-swot span-swot-blue">Análise das oportunidade</span>
                                            </div>
                                            <div className="modal-body-swot" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                {SwotRelatorio && SwotRelatorio?.oportunidades.length > 0 ?
                                                    SwotRelatorio?.oportunidades?.map((item, index) => {
                                                        return (
                                                            <div style={{ width: '100%' }} key={index}>
                                                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
                                                                    <span style={{ fontWeight: 600 }}>{item.capacidadeNegocioDemanda.titulo ? item.capacidadeNegocioDemanda.titulo : '-'}</span>
                                                                    <span>{item.gut}</span>
                                                                </div>
                                                                <ProgressBar min={0} max={125} id='progress-oportunidade' now={item.gut} style={{ width: '100%' }} />
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <p>Não foram adicionadas oportunidades a esse SWOT.</p>
                                                }
                                            </div>
                                        </div>

                                        <div style={{ border: '1px solid #e4e4e7', borderRadius: '8px', width: '100%' }}>
                                            <div style={{ padding: '16px', borderBottom: '1px solid #e4e4e7' }}>
                                                <span className="span-swot span-swot-yellow">Análise das ameaças</span>
                                            </div>
                                            <div className="modal-body-swot" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                {SwotRelatorio && SwotRelatorio?.ameacas.length > 0 ?
                                                    SwotRelatorio?.ameacas?.map((item, index) => {
                                                        return (
                                                            <div style={{ width: '100%' }} key={index}>
                                                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
                                                                    <span style={{ fontWeight: 600 }}>{item.capacidadeNegocioDemanda.titulo ? item.capacidadeNegocioDemanda.titulo : '-'}</span>
                                                                    <span>{item.gut}</span>
                                                                </div>
                                                                <ProgressBar min={0} max={125} id='progress-ameaca' now={item.gut} style={{ width: '100%' }} />
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <p>Não foram adicionadas ameaças a esse SWOT.</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="matriz" title="Matriz">
                                {SwotMatriz &&
                                    <>
                                        <div className='modal-swot' style={{ padding: '0 20px 20px 20px' }}>
                                            <h1 className="h1-modal">Capacidades Atuais</h1>
                                            <div style={{ display: 'flex', gap: '20px' }}>
                                                <div style={{ width: '50%' }} className='card-swot card-swot-green'>
                                                    <h3>Forças</h3>
                                                    <ul>
                                                        {SwotMatriz && SwotMatriz?.forcas.length > 0 ?
                                                            SwotMatriz?.forcas.map((item, index) => (
                                                                <li key={index}>
                                                                    <span>{item.capacidadeNegocioDemanda.titulo}</span>
                                                                    <span>{item.gut}</span>
                                                                </li>
                                                            ))
                                                            :
                                                            <p>Não foram adicionadas forças a esse SWOT.</p>
                                                        }
                                                    </ul>
                                                </div>
                                                <div style={{ width: '50%' }} className='card-swot card-swot-red'>
                                                    <h3>Fraquezas</h3>
                                                    <ul>
                                                        {SwotMatriz && SwotMatriz?.fraquezas.length > 0 ?
                                                            SwotMatriz?.fraquezas.map((item, index) => (
                                                                <li key={index}>
                                                                    <span>{item.capacidadeNegocioDemanda.titulo}</span>
                                                                    <span>{item.gut}</span>
                                                                </li>
                                                            ))
                                                            :
                                                            <p>Não foram adicionadas fraquezas a esse SWOT.</p>
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='modal-swot' style={{ marginTop: '20px', padding: '0 20px 20px 20px' }}>
                                            <h1 className="h1-modal">Capacidades Desejadas</h1>
                                            <div style={{ display: 'flex', gap: '20px' }}>
                                                <div style={{ width: '50%' }} className='card-swot card-swot-blue'>
                                                    <h3>Oportunidades</h3>
                                                    <ul>
                                                        {SwotMatriz && SwotMatriz?.oportunidades.length > 0 ?
                                                            SwotMatriz?.oportunidades.map((item, index) => (
                                                                <li key={index}>
                                                                    <span>{item.capacidadeNegocioDemanda.titulo}</span>
                                                                    <span>{item.gut}</span>
                                                                </li>
                                                            ))
                                                            :
                                                            <p>Não foram adicionadas oportunidades a esse SWOT.</p>
                                                        }
                                                    </ul>
                                                </div>
                                                <div style={{ width: '50%' }} className='card-swot card-swot-yellow'>
                                                    <h3>Ameaças</h3>
                                                    <ul>
                                                        {SwotMatriz && SwotMatriz?.ameacas.length > 0 ?
                                                            SwotMatriz?.ameacas.map((item, index) => (
                                                                <li key={index}>
                                                                    <span>{item.capacidadeNegocioDemanda.titulo}</span>
                                                                    <span>{item.gut}</span>
                                                                </li>
                                                            ))
                                                            :
                                                            <p>Não foram adicionadas ameaças a esse SWOT.</p>
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </Tab>
                        </Tabs>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ModalRelatorioSwot