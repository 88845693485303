import '../../App.css';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ApiContext } from "../../context/ApiContext";
import Header from '../../components/header/Header';
import MenuLateral from '../../components/menu/MenuLateral'
import Footer from '../../components/footer/Footer'
import { Col, Form, Row, Popover, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FaSearch, FaPlus } from 'react-icons/fa';
import { MdCancel, MdCheckCircle, MdShare } from 'react-icons/md'
import { IoIosWarning, IoIosCheckmarkCircle } from "react-icons/io";
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Toolbar, Sort } from '@syncfusion/ej2-react-grids';
import { L10n } from '@syncfusion/ej2-base';
import ModalCriarDemanda from '../../components/modal/ModalCriarDemanda';
import ModalEditarDemanda from '../../components/modal/ModalEditarDemanda';
import ModalExcluirDemanda from '../../components/modal/ModalExcluirDemanda';
import ModalCancelarDemanda from '../../components/modal/ModalCancelarDemanda';
import ModalConcluirDemanda from '../../components/modal/ModalConcluirDemanda';
import ModalCompartilharDemanda from '../../components/modal/ModalCompartilharDemanda';
import moment from 'moment/moment';
import Cookies from 'js-cookie'
import { TbTrash, TbPencil } from "react-icons/tb";
import { StyleContext } from '../../context/StyleContext';

const Demanda = () => {
    const { state } = useLocation()
    const role = Cookies.get('Role')

    const { ObterDemanda, setDescricao, setCenarioAtual,
        setProblemaCenarioAtual,
        setObjetivo,
        setResultado,
        setNecessidade,
        setOportunidade,
        setDataLimite, setEsforco, setLoading, Setores, ListaArea, Linhas, Fases, ListaFases, ListaLinhaAtendimento, Demandas, ListaDemanda, EditarDemandaModal, setModalEditarDemanda, setGut, setGutGravidade, setGutUrgencia, setGutTendencia, setBSC, setDemandaAtualKey, Success, Error, ExcluirDemandaModal, setModalExcluirDemanda, setgutBSC, openModal, setOpenModal, FiltroDemanda, setFiltroDemanda, ListaCiclos, Ciclos, loading, ObterBSC, CancelarDemandaModal, setCancelarDemandaModal, setConcluirDemandaModal, ConcluirDemandaModal, setCompartilharDemandaModal, CompartilharDemandaModal } = useContext(ApiContext)

    const { btnCriar, btnPesquisar, h3Demanda, corTerciaria } = useContext(StyleContext)

    const [cicloDemandaKey, setCicloDemandaKey] = useState("")

    const sortingOptions = {
        columns: [{ field: 'id', direction: 'Descending' }]
    };

    L10n.load({
        'pt-BR': {
          grid: {
            EmptyRecord: 'Nenhum registro encontrado',
          },
        },
      });

    const handleChange = (event) => {
        const { type, checked, name, value } = event.target;
        if (type === 'checkbox') {
            setFiltroDemanda({ ...FiltroDemanda, [name]: checked })
        } else {
            setFiltroDemanda({ ...FiltroDemanda, [name]: value })
        }
    }

    const handleSelectChange = (e) => {
        const selectedChave = e.target.selectedOptions[0].getAttribute('data-chave');
        setFiltroDemanda({ ...FiltroDemanda, [e.target.name]: e.target.value })
        setCicloDemandaKey(selectedChave);
    };

    const CriarDemanda = () => {
        setOpenModal(true)
        setDescricao('')
        setCenarioAtual('')
        setProblemaCenarioAtual('')
        setObjetivo('')
        setResultado('')
        setNecessidade('')
        setOportunidade('')
        setDataLimite('')
        setEsforco('')
    }

    const selecionarDem = (props) => {
        ObterDemanda(props.chave)
            .then(() => {
                EditarDemanda(props)
            })
    }

    const EditarDemanda = (props) => {
        setDemandaAtualKey(props.chave)
        setGut(props.guT_Pontuacao)
        setGutGravidade(props.guT_Gravidade)
        setGutUrgencia(props.guT_Urgencia)
        setGutTendencia(props.guT_Tendencia)
        setgutBSC(props.guT_BSC_Pontuacao)
        // setBSC(props.pesoBSC)
        setModalEditarDemanda(true)
    }

    const ExcluirDemanda = (props) => {
        setDemandaAtualKey(props.chave)
        setModalExcluirDemanda(true)
    }

    const CancelarDemanda = (props) => {
        setDemandaAtualKey(props.chave)
        setCancelarDemandaModal(true)
    }

    const ConcluirDemanda = (props) => {
        setDemandaAtualKey(props.chave)
        setConcluirDemandaModal(true)
    }

    const CompartilharDemanda = (props) => {
        setDemandaAtualKey(props.chave)
        setCompartilharDemandaModal(true)
    }

    const Pesquisar = () => {
        setLoading(true)
        ListaDemanda(true, "")
            .then(() => {
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }

    function carregando() {
        return new Promise(function (resolve) {
            setLoading(true)
            setTimeout(resolve, 1)
        });
    }

    function naoCarregando() {
        return new Promise(function (resolve) {
            setLoading(resolve, false)
        });
    }

    const chamarLista = useCallback(async () => {
        await carregando()
        await ListaLinhaAtendimento()
        await ListaArea()
        if (state) {
            await ListaDemanda(true, "")
        } else {
            await ListaDemanda(false, "")
        }
        await ListaFases()
        await ListaCiclos()
        // if (cicloDemandaKey) {
        //     await ObterBSC(cicloDemandaKey)
        // }
        await naoCarregando()
    })

    const popover = (descricao) => {
        return { __html: descricao };
    };

    useEffect(() => {
        chamarLista()
    }, [cicloDemandaKey])

    useEffect(() => {
        if (openModal || EditarDemandaModal || ExcluirDemandaModal || CancelarDemandaModal || ConcluirDemandaModal || CompartilharDemandaModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [openModal, EditarDemandaModal, ExcluirDemandaModal, CancelarDemandaModal, ConcluirDemandaModal, CompartilharDemandaModal]);

    const gridTemplate = useCallback((props, index) => {
        return (
            <tr className='tr-dem' key={index} >
                <td className="td-dem">{props.id}</td>
                <td className="td-dem">{props.criacaoUsuarioNome}</td>
                <OverlayTrigger placement="bottom" overlay={<Popover id="popover-basic">
                    <Popover.Header as="h3">Descrição</Popover.Header>
                    <Popover.Body dangerouslySetInnerHTML={popover(props.descricao)}></Popover.Body>
                </Popover>}>
                    <td className="td-dem">{props.titulo}</td>
                </OverlayTrigger>
                <td className="td-dem">{props.faseDescricao}</td>
                <td className="td-dem">{moment(props.criacaoDataHora).format("DD/MM/YYYY")}</td>
                <td className="td-dem">{props.areaDescricao ? props.areaDescricao : "-"}</td>
                <td className="td-dem">
                    {(props.cicloDescricao === null) ? (
                        "-"
                    ) : (
                        props.cicloDescricao
                    )}
                </td>
                <td className="td-dem">
                    {(props.tipoDescricao === null) ? (
                        "-"
                    ) : (
                        props.tipoDescricao
                    )}
                </td>
                <td className="td-dem">{props.linhaAtendimentoDescricao}</td>
                <td className="td-dem">{props.solicitante}</td>
                <td className="td-dem">
                    {(props.dataLimite === null) ? (
                        "-"
                    ) : (
                        moment(props.dataLimite).format("DD/MM/YYYY")
                    )}
                </td>
                <td className="td-dem">{props.guT_Pontuacao}</td>
                <td className="td-dem">{props.guT_BSC_Pontuacao}</td>
                <td className="td-dem" style={{ textAlign: 'right' }}>
                    <OverlayTrigger
                        placement="left"
                        overlay={<Tooltip id="button-tooltip">
                            Editar demanda
                        </Tooltip>}
                    ><button className='btn-dem' style={{ color: `${corTerciaria}` }} onClick={() => selecionarDem(props)}><TbPencil /></button>
                    </OverlayTrigger>
                    {!props.cancelamentoDataHora &&
                        (props.conclusaoDataHora ?
                            <OverlayTrigger
                                placement="left"
                                overlay={<Tooltip id="button-tooltip">
                                    Demanda concluída
                                </Tooltip>}
                            ><button className='btn-concluida'><MdCheckCircle /></button>
                            </OverlayTrigger>
                            :
                            <OverlayTrigger
                                placement="left"
                                overlay={<Tooltip id="button-tooltip">
                                    Concluir demanda
                                </Tooltip>}
                            ><button className='btn-dem' style={{ color: `${corTerciaria}` }} onClick={() => ConcluirDemanda(props)}><MdCheckCircle /></button>
                            </OverlayTrigger>
                        )
                    }
                    {!props.conclusaoDataHora &&
                        (props.cancelamentoDataHora ?
                            <OverlayTrigger
                                placement="left"
                                overlay={<Tooltip id="button-tooltip">
                                    Demanda cancelada
                                </Tooltip>}
                            ><button className='btn-cancelada'><MdCancel /></button>
                            </OverlayTrigger>
                            :
                            <OverlayTrigger
                                placement="left"
                                overlay={<Tooltip id="button-tooltip">
                                    Cancelar demanda
                                </Tooltip>}
                            ><button className='btn-dem' style={{ color: `${corTerciaria}` }} onClick={() => CancelarDemanda(props)}><MdCancel /></button>
                            </OverlayTrigger>
                        )
                    }
                    <OverlayTrigger
                        placement="left"
                        overlay={<Tooltip id="button-tooltip">
                            Compartilhar demanda
                        </Tooltip>}
                    ><button className='btn-dem' style={{ color: `${corTerciaria}` }} onClick={() => CompartilharDemanda(props)}><MdShare /></button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="left"
                        overlay={<Tooltip id="button-tooltip">
                            Excluir demanda
                        </Tooltip>}
                    ><button className='btn-dem' style={{ color: `${corTerciaria}` }} onClick={() => ExcluirDemanda(props)}><TbTrash /></button>
                    </OverlayTrigger>
                </td>
            </tr >);
    }, []);

    return (
        <div>

            {EditarDemandaModal &&
                <ModalEditarDemanda priorizacao={""} />
            }
            {ExcluirDemandaModal &&
                <ModalExcluirDemanda />
            }
            {CancelarDemandaModal &&
                <ModalCancelarDemanda />
            }
            {ConcluirDemandaModal &&
                <ModalConcluirDemanda />
            }
            {CompartilharDemandaModal &&
                <ModalCompartilharDemanda />
            }
            {Success &&
                <div className='success'>
                    <IoIosCheckmarkCircle className='error-icon' />{Success}
                </div>
            }
            {Error &&
                <div className='error'>
                    <IoIosWarning className='error-icon' />{Error}
                </div>
            }
            <Header pagina={'Demandas'}></Header>
            <MenuLateral></MenuLateral>
            <div className="container-dem">
                <div className='header-dem'>
                    {openModal &&
                        <ModalCriarDemanda closeModal={setOpenModal} priorizacao={''} />
                    }
                    {(role === "admin" || role === "supervisor") && <button className='btn-dash btn-criar' style={btnCriar} onClick={() => CriarDemanda()}><FaPlus style={{ marginRight: "10px" }} />Criar demanda</button>
                    }
                </div>
                {loading &&
                    <div className="loading-screen">
                        <div className="loader-container">
                            <div className="spinner"></div>
                        </div>
                    </div>
                }
                <div>
                    <div className='row filtro-demanda'>
                        <div className='col-12'>
                            <Row style={{ display: 'flex', alignItems: 'center' }}>
                                <Form.Group as={Col} xl="2" lg="2">
                                    <Form.Control placeholder='Nº demanda' onChange={handleChange} name="numero" />
                                </Form.Group>
                                <Form.Group as={Col} xl="1" lg="1">
                                    <Form.Select aria-label="Default select example" onChange={handleChange} name="faseId">
                                        <option value="0">Fase</option>
                                        {
                                            Fases?.map((item) => {
                                                return (
                                                    <option key={item.id} value={item.id}>{item.descricao}</option>
                                                )
                                            })

                                        }
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} xl="2" lg="3">
                                    <Form.Select defaultValue={FiltroDemanda?.linhaAtendimentoId} aria-label="Default select example" onChange={handleChange} name="linhaAtendimentoId">
                                        <option value="0">Linha atendimento</option>
                                        {
                                            Linhas?.map((item) => {
                                                return (
                                                    <option key={item.id} value={item.id}>{item.descricao}</option>
                                                )
                                            })

                                        }
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} xl="2" lg="3">
                                    <Form.Select aria-label="Default select example" onChange={handleChange} name="setorGerenciaDemandaId">
                                        <option value="0">Gerência solicitante</option>
                                        {Setores?.map((s) => {
                                            return (
                                                <option key={s.descricao} value={s.id}>{s.descricao}</option>
                                            )

                                        })}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} xl="1" lg="1">
                                    <Form.Select aria-label="Default select example" onChange={handleSelectChange} name="cicloId">
                                        <option value="0">Ciclo</option>
                                        {Ciclos?.map((c) => {
                                            return (
                                                <option key={c.id} value={c.id} data-chave={c.chave}>{c.descricao} ({c.usuarioNome})</option>
                                            )

                                        })}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} xl="2" lg="2">
                                    <Form.Control type="number" placeholder='GUT/BSC (Inicial)' name="guT_BSC_Pontuacao_Inicial" onChange={handleChange} />
                                </Form.Group>
                                <Form.Group as={Col} xl="2" lg="2">
                                    <Form.Control type="number" placeholder='GUT/BSC (Final)' name="guT_BSC_Pontuacao_Final" onChange={handleChange} />
                                </Form.Group>

                            </Row>
                            <Row className='mt-3 mb-3'>
                                <Form.Group as={Col} xl="5" lg="5">
                                    <Form.Control placeholder='Título/Descrição' onChange={handleChange} name="descricao" />
                                </Form.Group>
                                <Col style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex' }} className='mt-1'>
                                        <Form.Group>
                                            {['checkbox'].map((type) => (
                                                <div key={`inline-${type}`}>
                                                    <Form.Check
                                                        inline
                                                        label="Atrasadas"
                                                        type={type}
                                                        id={`inline-${type}-1`}
                                                        onChange={handleChange}
                                                        name="somenteAtrasadas"
                                                        checked={FiltroDemanda.somenteAtrasadas}
                                                    />
                                                </div>
                                            ))}
                                        </Form.Group>
                                        <Form.Group>
                                            {['checkbox'].map((type) => (
                                                <div key={type}>
                                                    <Form.Check
                                                        inline
                                                        label="Sem ciclo"
                                                        type={type}
                                                        id={`inline-${type}-2`}
                                                        onChange={handleChange}
                                                        name="somenteSemCiclo"
                                                        checked={FiltroDemanda.somenteSemCiclo}
                                                    />
                                                </div>
                                            ))}
                                        </Form.Group>
                                        <Form.Group>
                                            {['checkbox'].map((type) => (
                                                <div key={`inline-${type}`}>
                                                    <Form.Check
                                                        inline
                                                        label="Canceladas"
                                                        type={type}
                                                        id={`inline-${type}-3`}
                                                        onChange={handleChange}
                                                        name="somenteCanceladas"
                                                        checked={FiltroDemanda.somenteCanceladas}
                                                    />
                                                </div>
                                            ))}
                                        </Form.Group>
                                        <Form.Group>
                                            {['checkbox'].map((type) => (
                                                <div key={`inline-${type}`}>
                                                    <Form.Check
                                                        inline
                                                        label="Concluídas"
                                                        type={type}
                                                        id={`inline-${type}-4`}
                                                        onChange={handleChange}
                                                        name="somenteConcluidas"
                                                        checked={FiltroDemanda.somenteConcluidas}
                                                    />
                                                </div>
                                            ))}
                                        </Form.Group>
                                    </div>
                                    <Form.Group>
                                        <button className='btn-dash btn-pesquisar' style={btnPesquisar} onClick={() => Pesquisar()}><FaSearch style={{ marginRight: '5px' }} /> Pesquisar</button>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <h3 className='h3-demanda' style={h3Demanda}>Demandas</h3>
                    <GridComponent locale='pt-BR' id="grid" dataSource={Demandas} rowTemplate={gridTemplate} sortSettings={sortingOptions} pageSettings={{ pageSize: 50, pageCount: 5 }} allowSorting={true} rowHeight={60} allowPaging={true} >
                        <ColumnsDirective>
                            <ColumnDirective field='id' headerText='Nº' width={80} />
                            <ColumnDirective field='criacaoUsuarioNome' headerText='Usuário' />
                            <ColumnDirective field='titulo' headerText='Título' />
                            <ColumnDirective field='faseDescricao' headerText='Fase' />
                            <ColumnDirective field='criacaoDataHora' headerText='Data criação' />
                            <ColumnDirective field='areaDescricao' headerText='Área' />
                            <ColumnDirective field='cicloDescricao' headerText='Ciclo' />
                            <ColumnDirective field='tipoDescricao' headerText='Tipo' />
                            <ColumnDirective field='linhaAtendimentoDescricao' headerText='Linha' />
                            <ColumnDirective field='solicitante' headerText='Solicitante' />
                            <ColumnDirective field='dataLimite' headerText='Data limite' />
                            <ColumnDirective field='guT_Pontuacao' headerText='GUT' width={90} />
                            <ColumnDirective field='guT_BSC_Pontuacao' headerText='GUT/BSC' width={120} />
                            <ColumnDirective headerText='Opções' textAlign="right" width={210} />
                        </ColumnsDirective>
                        <Inject services={[Page, Sort, Toolbar]} />
                    </GridComponent>
                </div>
            </div>
            <Footer></Footer>
        </div>
    );
}

export default React.memo(Demanda);