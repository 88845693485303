import '../../App.css'
import React, { useContext } from "react";
import { AiOutlineAppstore, AiOutlineFolderOpen, AiOutlineBarChart, AiOutlineFileAdd, AiOutlineCalendar } from 'react-icons/ai';
import { IoLogOutOutline } from "react-icons/io5";
import { TbUrgent } from "react-icons/tb";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { slide as Slider } from 'react-burger-menu';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from "../../context/AuthContext";
import Cookies from 'js-cookie'
import { ApiContext } from '../../context/ApiContext';
import { StyleContext } from '../../context/StyleContext';

function MenuLateral() {

    const { logoMenu } = useContext(StyleContext)

    const empresa = process.env.REACT_APP_EMPRESA;

    let logo
    if (empresa === "COAMO") {
        logo = require('../../images/logo.png')
    } else {
        logo = require('../../images/smartDemand2.png')
    }

    const { setAuth } = useContext(AuthContext)

    const { FiltroDemanda, setFiltroDemanda } = useContext(ApiContext)

    const navigate = useNavigate()

    const role = Cookies.get('Role')

    const navigateDemanda = () => {
        setFiltroDemanda({
            ...FiltroDemanda,
            linhaAtendimentoId: 0,
            setorGerenciaDemandaId: 0,
            cicloId: 0,
            somenteAtrasadas: false,
            guT_BSC_Pontuacao_Inicial: 0,
            guT_BSC_Pontuacao_Final: 0,
            faseId: 0,
            numero: 0,
            somenteSemCiclo: false,
            faseDemanda: '',
            somenteCanceladas: false,
            somenteConcluidas: false
        });
        navigate("/demanda")
    }

    const sair = () => {
        Cookies.remove("Token")
        Cookies.remove("Key")
        setAuth(false);
        navigate('/');
    }

    return (
        <Sidebar>
            <Slider>
                <img src={`${logoMenu}`} alt='Logo' width={90} style={{ margin: '0 0 30px 30px' }} />
                <Menu>
                    <MenuItem icon={<AiOutlineAppstore />} component={<Link to="/" />}> Dashboard </MenuItem>
                    {role == "admin" &&
                        <MenuItem icon={<AiOutlineBarChart />} component={<Link to="/bsc" />}> BSC </MenuItem>
                    }
                    {role == "admin" &&
                        <MenuItem icon={<HiOutlineDocumentReport />} component={<Link to="/swot" />}> SWOT </MenuItem>
                    }
                    <MenuItem icon={<AiOutlineFolderOpen />} component={<a onClick={() => navigateDemanda()} />}>Demandas </MenuItem>
                    {(role == "admin" || role == "supervisor") &&
                        <MenuItem icon={<TbUrgent />} component={<Link to="/priorizacaodemandas" />}> Priorização de Demandas </MenuItem>
                    }
                    {(role == "admin" || role == "supervisor") &&
                        <MenuItem icon={<AiOutlineCalendar />} component={<Link to="/ciclo" />}> Ciclos </MenuItem>
                    }
                    {role == 'admin' &&
                        <SubMenu label="Cadastros" icon={<AiOutlineFileAdd />}>
                            <MenuItem component={<Link to="/areas" />}> Áreas </MenuItem>
                            <MenuItem component={<Link to="/cargo" />}> Cargo </MenuItem>
                            <MenuItem component={<Link to="/capacidadenegocio" />}> Capacidade Negócio </MenuItem>
                            <MenuItem component={<Link to="/categorizacaoestrategica" />}> Categorização Estratégica </MenuItem>
                            <MenuItem component={<Link to="/competencia" />}> Competência </MenuItem>
                            <MenuItem component={<Link to="/linhaatendimento" />}> Linha Atendimento </MenuItem>
                            <MenuItem component={<Link to="/pessoa" />}> Pessoa </MenuItem>
                            <MenuItem component={<Link to="/planonegocios" />}> Plano de Negócios </MenuItem>
                            <MenuItem component={<Link to="/setorgerencia" />}> Setor Gerência </MenuItem>
                            <MenuItem component={<Link to="/sistema" />}> Sistemas </MenuItem>
                            <MenuItem component={<Link to="/tipo" />}> Tipo </MenuItem>
                            <MenuItem component={<Link to="/cadastrousuario" />}> Usuário </MenuItem>
                        </SubMenu>
                    }
                    <hr style={{
                        margin: '1.5em 0',
                        border: '0.5px solid #dbdbdb'
                    }} />
                    <MenuItem onClick={() => sair()} icon={<IoLogOutOutline />}> Sair </MenuItem>
                </Menu>
            </Slider>
        </Sidebar>
    )
}

export default MenuLateral