import '../../App.css';
import React, { useContext, useEffect, useState, useLayoutEffect, useCallback } from 'react';
import { ApiContext } from '../../context/ApiContext';
import Header from '../../components/header/Header';
import MenuLateral from '../../components/menu/MenuLateral';
import Footer from '../../components/footer/Footer'
import ModalCriarDemanda from '../../components/modal/ModalCriarDemanda';
import { useNavigate } from 'react-router-dom';
import { FaRegLightbulb, FaPlus, FaCheck } from 'react-icons/fa'
import { FiAlertTriangle } from 'react-icons/fi'
import { IoIosWarning, IoIosCheckmarkCircle, IoMdEye } from "react-icons/io";
import { RxRocket } from "react-icons/rx";
import { TbFileSearch } from "react-icons/tb";
import ProgressBar from 'react-bootstrap/ProgressBar';
import CircularProgress from '@mui/joy/CircularProgress';
import imgempty from '../../images/looking.svg'
import { AccumulationChartComponent, AccumulationDataLabel, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective, AccumulationTooltip, AccumulationLegend, Inject, PieSeries } from '@syncfusion/ej2-react-charts';
import Cookies from 'js-cookie'
import { StyleContext } from '../../context/StyleContext';

function Dashboard() {
    const navigate = useNavigate()

    const [DadoDash, setDadoDash] = useState()
    const [LinhaLista, setLinhaLista] = useState()
    const [Fases, setFases] = useState()
    const [isLoading, setLoading] = useState(false)

    const { Success, Error, openModal, setOpenModal, ListaDashboard, DashboardImpacto, setFiltroDemanda, FiltroDemanda, setDescricao, ListaLinhaAtendimento } = useContext(ApiContext)
    const { btnVer, btnCriar } = useContext(StyleContext)
    let demandas = []
    const key = Cookies.get('Key')
    const role = Cookies.get('Role')

    DadoDash?.map((d) => {
        return (
            demandas = [
                { x: 'Em atraso', y: d.demandasEmAtraso, text: 'Atraso', fill: '#F87171' },
                { x: 'Em andamento', y: d.demandasEmAndamento, text: 'Andamento', fill: '#60A5FA' },
                { x: 'Concluída', y: d.demandasConcluidas, text: 'Concluída', fill: '#059669' },
            ]
        )
    })

    let impacto = [];
    let corImp = ''

    DashboardImpacto?.map((imp) => {
        switch (imp.descricao) {
            case "Médio":
                corImp = "#FACC15"
                break;
            case "Baixo":
                corImp = '#16A34A'
                break;
            case "Alto":
                corImp = "#F87171"
                break;
            default:
                break;
        }
        return (
            impacto.push({ x: imp.descricao, y: imp.percentual.toFixed(2), text: imp.descricao, fill: corImp })
        )

    })

    let investimento = [];
    let corLinha = ''

    LinhaLista?.map((l) => {
        switch (l.letra) {
            case 1:
                corLinha = "#FF4553"
                break;
            case 2:
                corLinha = '#EB7825'
                break;
            case 3:
                corLinha = "#F3C72E"
                break;
            case 4:
                corLinha = "#B1C933"
                break;
            case 5:
                corLinha = "#70ad47"
                break;
            case 6:
                corLinha = "#52B7C5"
                break;
            case 7:
                corLinha = "#5253C5"
                break;
            case 8:
                corLinha = "#AD69C5"
                break;
            case 9:
                corLinha = "#93b6ea"
                break;
            case 10:
                corLinha = "#fc888e"
                break;
            case 11:
                corLinha = "#B0A0D6"
                break;
            case 12:
                corLinha = "#6deded"
                break;
            case 13:
                corLinha = "#d7fcae"
                break;
            case 14:
                corLinha = "#fcc7e3"
                break;
            case 15:
                corLinha = "#f9d875"
                break;
            case 16:
                corLinha = "#8fef7f"
                break;
            case 17:
                corLinha = "#FA6F52"
                break;
            case 18:
                corLinha = "#D7BDE2"
                break;
            case 19:
                corLinha = "#A3E4D7"
                break;
            case 20:
                corLinha = "#F1948A"
                break;
            case 21:
                corLinha = "#D2F2E2"
                break;
            case 22:
                corLinha = "#F2D7D5"
                break;
            case 23:
                corLinha = "#E6B0AA"
                break;
            case 24:
                corLinha = "#F7DC6F"
                break;
            case 25:
                corLinha = "#FDEBD0"
                break;
            case 26:
                corLinha = "#A2D9CE"
                break;
            default:
                break;
        }
        return (
            investimento.push({ x: l.descricao, y: l.percentual.toFixed(2), text: l.letra, fill: corLinha })
        )
    })

    const icon = (descricao) => {
        switch (descricao) {
            case "Ideia":
                return <FaRegLightbulb />
            case "Entendimento":
                return <TbFileSearch />
            case "Priorização":
                return <FiAlertTriangle />
            case "Execução":
                return <RxRocket />
            case "Concluída":
                return <FaCheck />
            default:
                break;
        }
    }

    const setarFiltro = async (id) => {
        const newObj = {
            ...FiltroDemanda,
            numero: 0,
            faseId: 0,
            linhaAtendimentoId: id,
            setorGerenciaDemandaId: 0,
            cicloId: 0,
            guT_BSC_Pontuacao_Inicial: 0,
            guT_BSC_Pontuacao_Final: 0,
            somenteAtrasadas: false,
            somenteSemCiclo: false,
            somenteCanceladas: false,
            somenteConcluidas: false
        }
        setFiltroDemanda(newObj)
    }

    const pesquisar = async (id) => {
        await setarFiltro(id)
        await ListaLinhaAtendimento()
        navigate("/demanda", { state: true })

    }

    const tooltip = {
        enable: true,
        header: '% de Demanda por Linha'
    };

    const tooltipRender = (args) => {
        let value = args.point.y / args.series.sumOfPoints * 100;
        let sigla = args.point.x.split('-')[0]
        args.text = sigla + ': ' + Math.ceil(value) + '' + '%';
    };

    const getDashInfo = useCallback(async () => {
        try {
            setLoading(true)
            const result = await Promise.all([
                ListaDashboard()
            ])
            const dash = result[0]
            if (dash) {
                setDadoDash(dash[0])
                setLinhaLista(dash[1])
                setFases(dash[2])
            }
        }
        catch (error) {
            setLoading(false)
        }
        finally {
            setLoading(false)
        }

    })

    const navigateDemanda = () => {
        setFiltroDemanda({
            ...FiltroDemanda,
            linhaAtendimentoId: 0,
            setorGerenciaDemandaId: 0,
            cicloId: 0,
            somenteAtrasadas: false,
            guT_BSC_Pontuacao_Inicial: 0,
            guT_BSC_Pontuacao_Final: 0,
            faseId: 0,
            numero: 0,
            somenteSemCiclo: false,
            faseDemanda: '',
            somenteCanceladas: false,
            somenteConcluidas: false
        });
        navigate("/demanda")
    }

    const abrir = () => {
        setOpenModal(true)
        setDescricao('')
    }

    useLayoutEffect(() => {
        if (key !== '') {
            getDashInfo()
        }
    }, [key])

    useEffect(() => {
        if (openModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [openModal]);

    return (
        <div>
            <Header pagina={'Dashboard'}></Header>
            <MenuLateral></MenuLateral>
            {Success &&
                <div className='success'>
                    <IoIosCheckmarkCircle className='error-icon' />{Success}
                </div>
            }
            {Error &&
                <div className='error'>
                    <IoIosWarning className='error-icon' />{Error}
                </div>
            }
            {isLoading ?
                <div className="loading-screen">
                    <div className="loader-container">
                        <div className="spinner"></div>
                    </div>
                </div>
                :
                <div className='container-dashboard'>
                    <div className='header-dashboard'>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <button className='btn-dash btn-ver' style={btnVer} onClick={() => navigateDemanda()}><IoMdEye style={{ marginRight: "10px", fontSize: '20px' }} />Ver demandas</button>
                            {openModal &&
                                <ModalCriarDemanda closeModal={setOpenModal} priorizacao={''} />
                            }
                            {(role === "admin" || role === "supervisor") && <button className='btn-dash btn-criar' style={btnCriar} onClick={() => abrir()}><FaPlus style={{ marginRight: "10px" }} />Criar demanda</button>
                            }
                        </div>
                    </div>
                    {(DadoDash === '' || DadoDash === null || typeof DadoDash === 'undefined') ? (
                        <div style={{ marginTop: "90px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <h1 className='h1-sem-demandas'>Você não possui demandas cadastradas!</h1>
                            <h2 className='h2-sem-demandas'>Crie uma nova demanda ou contate seu supervisor.</h2>
                            <img src={imgempty} className='img-empty' alt="Imagem indicando que não há demandas" />
                        </div>
                    ) : (

                        <>
                            <div className='container-grafico'>
                                <div className='section-grafico w-100'>
                                    <h2>Todas as demandas</h2>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
                                        <AccumulationChartComponent id='demandas' tooltip={{ enable: true }} width='200' height='200'>
                                            <Inject services={[PieSeries, AccumulationTooltip]} />
                                            <AccumulationSeriesCollectionDirective>
                                                <AccumulationSeriesDirective dataSource={demandas} xName='x' yName='y' type='Pie' pointColorMapping='fill' innerRadius='70%' border={{ color: '#fff', width: "1px" }}>
                                                </AccumulationSeriesDirective>
                                            </AccumulationSeriesCollectionDirective>
                                        </AccumulationChartComponent>
                                        <div className='status'>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div className='status-concluido' /> Concluídas
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span className='status-andamento'></span> Em andamento
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span className='status-atraso'></span> Em atraso
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='section-grafico w-100'>
                                    <h2>Impacto no negócio (riscos)</h2>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
                                        <AccumulationChartComponent id='impacto' tooltip={{ enable: true }} width='200' height='200'>
                                            <Inject services={[PieSeries, AccumulationTooltip]} />
                                            <AccumulationSeriesCollectionDirective>
                                                <AccumulationSeriesDirective dataSource={impacto} xName='x' yName='y' type='Pie' pointColorMapping='fill' border={{ color: '#fff', width: "1px" }}
                                                />
                                            </AccumulationSeriesCollectionDirective>
                                        </AccumulationChartComponent>
                                        <div className='status'>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div className='status-atraso' /> Alto
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span className='status-prioritario'></span> Médio
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span className='status-concluido'></span> Baixo
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='section-grafico w-100'>
                                    <h2>Demanda por linha</h2>
                                    <AccumulationChartComponent id='investimento'
                                        tooltip={tooltip}
                                        legendSettings={{ visible: false }}
                                        enableSmartLabels={true}
                                        enableBorderOnMouseMove={true}
                                        tooltipRender={tooltipRender}
                                        width='350' height='200'
                                        center={{ x: '50%', y: '50%' }}
                                    >
                                        <Inject services={[PieSeries, AccumulationDataLabel, AccumulationTooltip, AccumulationLegend]} />
                                        <AccumulationSeriesCollectionDirective>
                                            <AccumulationSeriesDirective dataSource={investimento} xName='x' yName='y' type='Pie'
                                                dataLabel={{
                                                    visible: true,
                                                    position: 'Outside', name: 'text',
                                                    font: {
                                                        fontWeight: '600'
                                                    },
                                                    connectorStyle: { length: '20px', type: 'Curve' }
                                                }}
                                                radius='55%'
                                                animation={{ enable: true }}
                                                explode={true}
                                                pointColorMapping='fill'
                                                border={{ color: '#fff', width: "1px" }}

                                            />
                                        </AccumulationSeriesCollectionDirective>
                                    </AccumulationChartComponent>
                                </div>
                            </div>
                            <div className='container-fases'>
                                <div className='content-fases col-lrg-7'>
                                    {Fases?.map((item, index) => {
                                        return (
                                            <div className='card-fases' key={index}>
                                                <div className='icon-fases'>{icon(item.descricao)}</div>
                                                <div>
                                                    <p className='titulo-fases'>{item.descricao}</p>
                                                    <p className='qtd-fases'>{item.quantidade}</p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className='section-grafico d-flex justify-content-between section-investimento col-lrg-5'>
                                    <div className='d-flex flex-column justify-content-between'>
                                        <h2>Investimento total</h2>
                                        <div>
                                            <span style={{ fontSize: '24px', fontWeight: '500' }}>50%</span>
                                            <ProgressBar variant="success" now={50} style={{ marginBottom: "10px" }} />
                                        </div>
                                    </div>
                                    <div className='card-investimento'>
                                        <h2><strong>R$ 5.000.000,00</strong></h2>
                                        <p style={{ marginBottom: '15px' }}>Realizado</p>
                                        <h2><strong>R$10.000.000,00</strong></h2>
                                        <p>Previsto</p>
                                    </div>
                                </div>
                            </div>
                            <div className='container-linha'>
                                <h2 style={{ marginBottom: '20px' }}>Percentual de demanda por linha</h2>
                                <div className='section-linha'>
                                    {LinhaLista?.map((i) => {
                                        return (
                                            <div key={i.id} className='item-linha' onClick={() => pesquisar(i.id)} name="linhaAtendimentoId">
                                                <div className='d-flex'>
                                                    {/* <Box sx={{ position: 'relative', display: 'inline-flex', marginRight: '15px' }}>
                                                        <CircularProgress variant="determinate" value={parseFloat(i.percentual)} />
                                                        <Box
                                                            sx={{
                                                                top: 0,
                                                                left: 0,
                                                                bottom: 0,
                                                                right: 0,
                                                                position: 'absolute',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="caption"
                                                                component="div"
                                                                color="text.secondary"
                                                                style={{ fontSize: "12px", textAlign: 'center' }}
                                                            >{`${i.percentual.toFixed(2)}%`}</Typography>
                                                        </Box>
                                                    </Box> */}
                                                    <div style={{ marginRight: '15px' }}>
                                                        <CircularProgress size="md" determinate value={parseFloat(i.percentual)} thickness={4} color='success'>
                                                            <span className='value-percentual'>{`${i.percentual.toFixed(2)}%`}</span>
                                                        </CircularProgress>
                                                    </div>
                                                    <div>
                                                        <p className="p-descricao"><b>{i.letra}</b> - {i.descricao}</p>
                                                        <p className="p-responsavel">{i.responsavel ? i.responsavel : 'Sem responsável'}</p>
                                                    </div>
                                                </div>
                                                <span className="p-responsavel">Qtd: {i.quantidade}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            }
            <Footer></Footer>
        </div>
    );

}

export default Dashboard;
